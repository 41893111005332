.btn {
    cursor: pointer;
    margin: 0;
    border: none;
    overflow: visible;
    font: inherit;
    color: inherit;
    text-transform: none;
    -webkit-appearance: none;
    display: inline-block;
    box-sizing: border-box;
    padding: 0 24px;
    vertical-align: middle;
    font-size: 16px;
    text-align: center;
    text-decoration: none;
    display: -webkit-inline-flex;
    display: inline-flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    align-items: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    position: relative;
    height: 48px;
    border-radius: 39px;
    line-height: 1;
    white-space: nowrap;
    font-weight: 500;
    -webkit-transition: color .25s ease-in-out,opacity .25s ease-in-out,border-color .25s ease-in-out,background-color .25s ease-in-out,box-shadow .25s ease-in-out,-webkit-transform .25s ease-in-out;
    transition: color .25s ease-in-out,opacity .25s ease-in-out,border-color .25s ease-in-out,background-color .25s ease-in-out,box-shadow .25s ease-in-out,transform .25s ease-in-out,-webkit-transform .25s ease-in-out;
}

.btn--small {
    height: 32px;
}

.btn-icon-only .btn__icon {
    position: absolute;
}

.btn__icon + .btn__text {
    margin-left: 16px;
}

.btn-green {
    color: #83ba26;
    border: 1px solid #83ba26;
    background: #fff;
    box-shadow: none;
}

.btn-green:hover {
    color: #fff;
    border: 1px solid #83ba26;
    background: linear-gradient(180deg, #b4f14b 16.67%, #83ba26 93.75%);
    box-shadow: 0 4px 4px rgba(85, 73, 55, .15);
}

.btn-green--outline {
    color: #83ba26;
    border: 1px solid #83ba26;
    background: #fff;
    box-shadow: none;
}

.btn-green--outline:hover {
    color: #83ba26;
    border: 1px solid #83ba26;
    background: #fff;
    box-shadow: 0 4px 4px rgba(85, 73, 55, .15);
}

.btn-green--filled {
    color: #fff;
    background: linear-gradient(180deg, #9cd836, #83ba26);
    box-shadow: none;
}

.btn-green--filled:hover {
    color: #fff;
    background: linear-gradient(180deg, #b4f14b 16.67%, #83ba26 93.75%);
    box-shadow: 0 4px 4px rgba(85, 73, 55, .15);
}

.btn-orange {
    color: #fff;
    border: 1px solid #f28f14;
    background: linear-gradient(180deg,#ffad47,#f28f14);;
    box-shadow: none
}

.btn-orange:hover {
    color: #fff;
    border: none;
    background: linear-gradient(180deg,#ffcb8b,#f28f14);
    box-shadow: 0 4px 4px rgba(85, 73, 55, .15);
}

.btn-orange--outline {
    color: #f28f14;
    border: 1px solid #f28f14;
    background: #fff;
    box-shadow: none
}

.btn-orange--outline:hover {
    color: #f28f14;
    border: 1px solid #f28f14;
    background: #fff;
    box-shadow: 0 4px 4px rgba(85, 73, 55, .15);
}

.btn-white {
    color: #f28f14;
    border: 1px solid #fff;
    background: #fff;
    box-shadow: 0 2px 8px rgba(85,73,55,.1);
}

.btn-white:hover {
    color: #f28f14;
    border: 1px solid #f28f14;
    background: #fff;
    box-shadow: 0 4px 4px rgba(85, 73, 55, .15);
}

.btn-transparent {
    color: #f28f14;
    border: 1px solid #ccc;
    background: transparent;
    box-shadow: none;
}

.btn-transparent:hover {
    color: #f28f14;
    border: 1px solid #f28f14;
    background: #fff;
    box-shadow: 0 4px 4px rgba(85, 73, 55, .15);
}

.btn__label-top {
    position: absolute;
    top: -4px;
    right: -5px;
    height: 20px;
    padding: 3px 7px 3px 8px;
    background-color: #fff;
    border: 1px solid #83ba26;
    border-radius: 46px;
    box-shadow: 0 4px 20px rgb(85 73 55 / 20%);
    font-size: .875rem;
    font-weight: 700;
    line-height: 1.07;
    letter-spacing: .04em;
    color: #f28f14;
}

.btn.btn-catalog .btn__icon {
    margin-left: 10px;
    width: 10px;
}

.btn.btn-menu.btn-orange {
    width: 48px;
    height: 48px;
    padding: 0;
    margin: 0 10px;
}

.sidebar-back .btn {
    background: #fff;
    box-shadow: 0 4px 4px rgba(85, 73, 55, .15);
    border: none;
}