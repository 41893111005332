.catalog-menu {
    border-radius: 4px;
    box-shadow: 0 2px 4px rgba(85, 73, 55, .1);
    max-width: 320px;
    background-color: #fff;
    position: relative;
    -webkit-transition: opacity .25s ease-in-out, -webkit-transform .25s ease-in-out;
    transition: opacity .25s ease-in-out, transform .25s ease-in-out, -webkit-transform .25s ease-in-out;
}

.catalog-menu__item {
    display: -webkit-box;
    display: -webkit-flex;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-flow: row nowrap;
    flex-flow: row nowrap;
    -webkit-box-align: center;
    -webkit-align-items: center;
    align-items: center;
    font-weight: 500;
    cursor: pointer;
    -webkit-transition: background-color .25s;
    transition: background-color .25s;

    border-bottom: 1px solid rgba(85, 73, 55, .2);
}

.catalog-menu__item-content {
    width: 100%;
    display: -webkit-box;
    display: -webkit-flex;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-flow: row nowrap;
    flex-flow: row nowrap;
    -webkit-box-align: center;
    -webkit-align-items: center;
    align-items: center;
}

.catalog-menu__item-icon {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 74px;
    flex: 0 0 74px;
    max-width: 66px;
}

.catalog-menu__item-icon img {
    margin: 0 auto;
}

.catalog-menu__item-text {
    padding: 16px 0;
    line-height: 120%;
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 auto;
    flex: 1 1 auto;
    -webkit-transition: color .25s;
    transition: color .25s;
    -webkit-transform: translateY(2px);
    transform: translateY(2px);
}

.catalog-menu__item-arrow {
    padding: 0 24px;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    flex: 0 0 auto;
    -webkit-transform: rotate(-90deg);
    transform: rotate(-90deg);
    opacity: .2;
    -webkit-transition: opacity .25s, color .25s;
    transition: opacity .25s, color .25s;
}

.catalog-menu__item:hover {
    background-color: #f9f8f8;
}

.catalog-menu__item:hover .catalog-menu__item-inner {
    visibility: visible;
    opacity: 1;
}

.catalog-menu__item-inner {
    position: absolute;
    left: 100%;
    bottom: 0;
    top: 0;
    z-index: 999;
    padding-left: 8px;
    -webkit-transition: visibility .25s ease-in-out, opacity .25s ease-in-out;
    transition: visibility .25s ease-in-out, opacity .25s ease-in-out;
    visibility: hidden;
    opacity: 0;
    cursor: default;
}

.catalog-menu__inner {
    box-shadow: 0 2px 20px rgba(85, 73, 55, .1);
    background-color: #fff;
    border-radius: 4px;
    overflow: hidden;
    max-width: 802px;
    width: calc(100vw - 429px);
    padding: 35px 16px 35px 0;
}

.catalog-menu__inner-container {
    overflow-y: auto;

    position: relative;
    padding: 5px 34px 5px 40px;
    min-height: 100%;
    max-height: calc(100vh - 300px);

    scrollbar-width: thin;
    scrollbar-color: rgba(59,58,57,.7) rgba(59,58,57,.1)
}


.catalog-menu__inner-container::-webkit-scrollbar {
    width: 5px
}

.catalog-menu__inner-container::-webkit-scrollbar-track {
    border-radius: 4px;
    background-color: #ebebeb
}

.catalog-menu__inner-container::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: #8f8d8e
}


.catalog-menu__inner-heading {
    font-weight: 700;
    font-size: 18px;
    line-height: 120%;
}

.catalog-menu__inner-block:not(:first-child) {
    margin-top: 48px;
}

.catalog-menu__inner-subcategory {
    margin-top: 16px;
    text-decoration: underline;
    font-weight: 400;
    line-height: 100%;
    font-size: 16px;
    display: -webkit-box;
    display: -webkit-flex;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-flow: row nowrap;
    flex-flow: row nowrap;
    -webkit-box-align: center;
    -webkit-align-items: center;
    align-items: center;
    -webkit-box-pack: start;
    -webkit-justify-content: start;
    justify-content: start;
    position: relative;
}

.catalog-menu__inner-subcategories--2col {
    display: grid;
    grid-template-columns: 1fr 1fr;
}

.catalog-menu__inner-subcategory:hover .catalog-menu__inner-last {
    visibility: visible;
    opacity: 1;
}

.catalog-menu__inner-last {
    -webkit-transition: visibility .25s ease-in-out, opacity .25s ease-in-out;
    transition: visibility .25s ease-in-out, opacity .25s ease-in-out;
    visibility: hidden;
    opacity: 0;
    position: absolute;
    top: 20px;
    padding-top: 10px;
    margin-bottom: 40px;
    z-index: 999;
}

.catalog-menu__inner-last-items {
    background-color: #fff;
    border-radius: 4px;
    box-shadow: 0 2px 20px rgba(85,73,55,.2);
    padding: 24px;
    text-decoration: underline;
    color: #554937;
}

.catalog-menu__inner-last-items>li:not(:first-child) {
    margin-top: 16px;
}



.mobile-menu .catalog-menu__item-text {
    padding: 24px 0;
    font-size: 18px;
    line-height: 140%;
}

.mobile-menu .catalog-menu__item {
    background: #fff;
}

.mobile-menu .catalog-menu__item:first-of-type {
    border-top: 1px solid rgba(85, 73, 55, .2);
}

.mobile-menu .catalog-menu__item-icon img {
    width: 30px;
}

.mobile-menu .catalog-menu__item-content:hover {
    color: #554937;
    background-color: #f9f8f8;
}


.mobile-menu__inner:first-of-type {
    border-top: 1px solid rgba(85, 73, 55, .2);
}

.mobile-menu__inner:hover {
    background-color: #f9f8f8;
}

.mobile-menu__inner {
    display: -webkit-box;
    display: -webkit-flex;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-flow: row nowrap;
    flex-flow: row nowrap;
    -webkit-box-align: center;
    -webkit-align-items: center;
    align-items: center;
    font-weight: 500;
    cursor: pointer;
    -webkit-transition: background-color .25s;
    transition: background-color .25s;

    flex-flow: row nowrap;
    border-bottom: 1px solid rgba(85, 73, 55, .2);
    background: #fff;
}

a.mobile-menu__inner-heading {
    padding: 16px 0;
    line-height: 120%;
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 auto;
    flex: 1 1 auto;
    -webkit-transition: color .25s;
    transition: color .25s;
    -webkit-transform: translateY(2px);
    transform: translateY(2px);
    padding: 24px 0;
    font-size: 18px;
    line-height: 140%;
    padding-left: 24px;
}