.not-found {
    padding-left: 40px;
    padding-right: 40px;
    display: flow-root;
    box-sizing: content-box;
    max-width: 1136px;
    margin-left: auto;
    margin-right: auto;
}

.not-found__content {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-flow: column;
    flex-flow: column;
    min-height: 500px;

    align-items: center;
    display: flex;
    justify-content: center;
}

@media (max-width: 639px) {
    .not-found__content {
        min-height:unset;
        margin-top: 40px
    }
}

@media (max-width: 424px) {
    .not-found__title {
        margin-top:20px;
        text-align: center;
        font-size: 24px
    }
}

.not-found__subtitle {
    font-weight: 400;
    font-size: 22px
}

@media (max-width: 424px) {
    .not-found__subtitle {
        font-size:18px
    }
}

.not-found__image {
    position: relative
}

@media (min-width: 425px) {
    .not-found__image {
        min-width:400px;
        min-height: 200px
    }
}

@media (max-width: 424px) {
    .not-found__image {
        width:100%;
        height: auto
    }
}
