.form {
    display: -webkit-box;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    max-width: 496px;
    margin: -10px;
}

.form-field {
    width: 100%;
    position: relative;
    margin: 10px;
    display: flex;
    justify-content: space-between;
}

.form-field__input {
    height: 48px;
    vertical-align: middle;
    display: inline-block;
    font-size: 1rem;
    max-width: 100%;
    width: 100%;
    color: #666;
    border-radius: 4px;
    background-color: #f3f2f1;
    border: 1px solid transparent;
    font-family: inherit;
    line-height: inherit;
    -webkit-transition: border-color .25s ease-in-out,background-color .25s ease-in-out,box-shadow .25s ease-in-out;
    transition: border-color .25s ease-in-out,background-color .25s ease-in-out,box-shadow .25s ease-in-out;
    padding: 0 16px;
}

.form-field__input.col-1-2 {
    width: calc(50% - 20px);
}