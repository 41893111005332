
.contacts-list {
    display: grid;
    grid-template-columns: repeat(3,1fr);
    grid-gap: 16px;
    margin-top: 48px;
    margin-bottom: 48px;
}

@media (max-width: 899px) {
    .contacts-list {
        grid-template-columns:repeat(2,1fr);
        grid-gap: 24px 8px
    }
}

@media (max-width: 639px) {
    .contacts-list {
        grid-template-columns:1fr
    }
}

.shop-card {
    background-color: #fff;
    box-shadow: 0 4px 20px rgba(85, 73, 55, .1);
    font-weight: 400;
    height: 100%;
    cursor: pointer;
    padding: 24px 32px;
    color: #554937;
    border-radius: 4px;
    overflow: hidden;
}

.shop-card:hover .shop-card__address {
    color: #f28f14
}

.shop-card__title {
    font-size: 1.375rem;
    margin-bottom: 16px;
    font-weight: 500
}

.shop-card__address {
    margin-bottom: 16px;
    text-decoration: underline;
    -webkit-transition: color .25s ease-in-out;
    transition: color .25s ease-in-out
}

.shop-card__description {
    color: rgba(85,73,55,.5)
}

.contacts-map {
    margin-top: 48px;
    background-color: #fafafa;
    background-image: -webkit-linear-gradient(left,#fafafa 25%,#dbdbdb 50%,#fafafa 85%);
    background-image: linear-gradient(90deg,#fafafa 25%,#dbdbdb 50%,#fafafa 85%);
    background-size: 600%;
    background-position: 0;
    will-change: background-position;
    -webkit-animation: yandex-map-loading-background 3s infinite ease-out;
    animation: yandex-map-loading-background 3s infinite ease-out;
    position: relative;

        width: 100%;
        height: 20em;
        max-height: 100vw;
}

@-webkit-keyframes yandex-map-loading-background {
    to {
        background-position: -100%
    }
}

@keyframes yandex-map-loading-background {
    to {
        background-position: -100%
    }
}

.contacts-map:empty:before {
    content: ""
}

.contacts-map:before {
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='96' height='69' viewBox='0 0 96 69' fill='%23fff'%3E%3Cpath d='M95.5 65.8L90.4 26c-.1-.9-.8-1.8-1.7-2.1l-8.6-3.1c-.8 1.5-1.6 3.1-2.4 4.6l7.7 2.8 4.4 34.3-18.3-5.9-1.3-20.2c-.9.4-1.9.6-3 .6-.7 0-1.5-.1-2.1-.3l1.3 20-16 5.8V28l6.4-2.6-2.4-4.6-6.4 2.7-17.6-7.3c-.6-.2-1.3-.3-1.9-.1L7.3 23.9c-.9.3-1.5 1.1-1.7 2.1L.5 65.8c-.1.9.2 1.7.9 2.3.7.6 1.6.7 2.4.5l22.9-7.4 20.4 7.4c.6.2 1.2.2 1.7 0l20.4-7.4 22.9 7.4c.3.1.5.1.8.1.6 0 1.2-.2 1.6-.6.7-.6 1.1-1.4 1-2.3zm-71.1-9.2L6.1 62.5l4.4-34.3 16.1-5.8-2.2 34.2zm21 5.9l-16-5.8 2.2-34.4L45.4 28v34.5z'/%3E%3Cpath d='M66.093 31.14c.57 1.147 2.209 1.147 2.779 0 0 0 6.483-12.77 7.98-15.783 1.495-3.013 1.566-6.384 0-9.54C74.285.58 67.945-1.502 62.744 1.152c-5.2 2.655-7.196 9.183-4.63 14.204 2.564 5.022 7.978 15.782 7.978 15.782zm1.425-25.395c2.779 0 4.987 2.223 4.987 5.021s-2.208 5.022-4.987 5.022c-2.778 0-4.987-2.224-4.987-5.022 0-2.726 2.209-5.021 4.987-5.021z'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: 50%;
    background-size: 15em
}

.contacts-map {
    width: 100%;
    height: 20em;
    max-height: 100vw
}

.contacts-map * {
    touch-action: auto!important
}