
.testimonials-wrapper {
    margin-bottom: 120px;
}

.testimonials-title {
    margin-bottom: 40px;
}

.testimonials-list {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 48px;
}

.testimonials-main__title {
    margin-bottom: 40px;
}

.testimonials-main__grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 48px;
}

.testimonial-card {
    padding: 24px 32px;
    color: #554937
}

@media (max-width: 639px) {
    .testimonial-card {
        padding-left: 24px;
        padding-right: 24px
    }
}

.testimonial-card {
    position: relative;
    background-color: #fff;
    box-shadow: 0 4px 20px rgba(85, 73, 55, .1);
    font-size: 1.125rem
}

@media (min-width: 900px) {
    .testimonial-card {
        min-height: 248px
    }
}

@media (max-width: 639px) {
    .testimonial-card {
        padding: 16px;
        font-size: .875rem
    }
}

.testimonial-card__title {
    margin: 0 32px 8px 0
}

@media (max-width: 639px) {
    .testimonial-card__title {
        font-size: 1rem
    }
}

.testimonial-card__date {
    margin-bottom: 24px
}

.testimonial-card__text {
    margin-bottom: 16px;
    display: none;
}

.testimonial-card__text--show {
    display: block;
    animation: fade-in 0.3s ease-in;
}

.testimonial-card__text--short:after {
    content: "\2026";
    color: #201004
}

.testimonial-card__text-toggle {
    text-decoration: underline;
    color: #83ba26;
    cursor: pointer;
    -webkit-transition: color .25s ease-in-out;
    transition: color .25s ease-in-out
}

.testimonial-card__text-toggle:hover {
    color: #f28f14
}

.testimonial-card__icon {
    position: absolute;
    right: 0;
    top: 0;
    padding-right: inherit;
}