.page-image {
    margin-bottom: 40px;
}

.page-image img {
    border-radius: 5px;
}

.page-container {
    padding: 0;
    margin-bottom: 120px;
}

.page-container:not(.page-container-fluid) {
    max-width: 752px;
}

.page-title {
    margin-bottom: 40px;
}

.page-title .title {
    font-size: 2rem;
}

.page-content {
    color: #3b3a39;
    font-size: 1.125rem;
    line-height: 1.5
}

@media (max-width: 639px) {
    .page-content {
        font-size:1rem
    }
}

.page-content>:first-child {
    margin-top: 0
}

.page-content__tableWrapper {
    display: -webkit-box;
    display: -webkit-flex;
    display: flex;
    width: calc(100% + 80px);
    margin-left: -40px;
    padding: 32px 40px;
    overflow-x: auto;
    overflow-y: hidden;
    background-color: #fff;
    border-radius: 4px;
    box-shadow: 0 2px 4px rgba(85,73,55,.1)
}

.page-content__tableWrapper:not(:last-child) {
    margin-bottom: 16px
}

@media (max-width: 639px) {
    .page-content__tableWrapper {
        width:100vw;
        border-radius: 0;
        margin: 0 -16px;
        padding-left: 16px;
        padding-right: 16px
    }

    .page-content__tableWrapper:after {
        content: "";
        padding-right: 16px
    }
}

.page-content h1 {
    margin-top: 1.5em;
    margin-bottom: .75em
}

.page-content h2 {
    margin-top: 1.66667em;
    margin-bottom: 1em
}

.page-content h3,.page-content h4,.page-content h5,.page-content h6 {
    margin-top: 1.33333em;
    margin-bottom: 1.33333em
}

.page-content img {
    height: auto!important;
    margin-bottom: 32px
}

.page-content ol li,.page-content ul li {
    position: relative;
    margin-top: 8px;
    margin-bottom: 8px;
    padding-left: 20px;
    line-height: 1.3
}

@media (max-width: 639px) {
    .page-content ol li,.page-content ul li {
        margin:8px 0
    }
}

.page-content ol li:before,.page-content ul li:before {
    position: absolute;
    left: 0
}

.page-content ol li:last-of-type,.page-content ul li:last-of-type {
    margin-bottom: 0
}

.page-content blockquote,.page-content ol,.page-content ul {
    margin: 24px 0
}

@media (max-width: 639px) {
    .page-content blockquote,.page-content ol,.page-content ul {
        margin:16px 0
    }
}

.page-content blockquote:last-child,.page-content ol:last-child,.page-content ul:last-child {
    margin-bottom: 0
}

.page-content ul li:before {
    background: -webkit-linear-gradient(top,#ffad47,#f28f14);
    background: linear-gradient(180deg,#ffad47,#f28f14);
    border-radius: 50%;
    content: "";
    width: 5px;
    height: 5px;
    top: .5rem
}

@media (max-width: 639px) {
    .page-content ul li:before {
        top:.4375rem
    }
}

.page-content ul li>ul {
    margin-left: 12px
}

.page-content ol {
    counter-reset: list-counter
}

.page-content ol li {
    counter-increment: list-counter
}

.page-content ol li:before {
    content: counter(list-counter) ".";
    top: .125rem;
    color: #f28f14;
    font-size: 1rem;
    font-weight: 500
}

@media (max-width: 639px) {
    .page-content ol li:before {
        font-size:.875rem;
        top: .0625rem
    }
}

.page-content table:last-child {
    margin-bottom: 0
}

.page-content table td,.page-content table th {
    background-color: transparent
}

.page-content table td:first-child,.page-content table th:first-child {
    padding-left: 0
}

.page-content table td:last-child,.page-content table th:last-child {
    padding-right: 0
}

.page-content table thead th {
    padding-top: 0;
    padding-bottom: 0;
    color: #554937;
    white-space: nowrap;
    font-size: .875rem;
    font-weight: 500;
    opacity: .4
}

@media (max-width: 424px) {
    .page-content table thead th {
        padding-bottom:8px
    }
}

.page-content table tbody tr:first-child {
    border-top: none
}

.page-content table tbody tr:last-child td {
    padding-bottom: 0
}

.page-content a,.page-content a a:hover {
    text-decoration: underline
}

.page-content em {
    color: unset
}

.SiteGallery {
    display: grid;
    grid-template: auto/repeat(3,1fr);
    grid-gap: 16px
}

@media (max-width: 639px) {
    .SiteGallery {
        grid-template-columns:repeat(2,1fr);
        grid-gap: 8px
    }
}

.SiteGallery__picture {
    position: relative;
    display: block;
    padding-top: 71.66667%;
    overflow: hidden;
    background-color: #dad5d2;
    border-radius: 4px
}

@media (max-width: 639px) {
    .SiteGallery__picture {
        padding-top:53.57143%
    }
}

.SiteGallery__image {
    -webkit-transition: -webkit-transform .5s;
    transition: -webkit-transform .5s;
    transition: transform .5s;
    transition: transform .5s,-webkit-transform .5s
}

.SiteGallery__link {
    cursor: -webkit-zoom-in;
    cursor: zoom-in
}

@media (hover: hover) {
    .SiteGallery__link:hover .SiteGallery__image {
        -webkit-transform:scale(1.1);
        transform: scale(1.1)
    }
}

.SiteGallery__link:active .SiteGallery__image,.SiteGallery__link:focus .SiteGallery__image {
    -webkit-transform: scale(1.1);
    transform: scale(1.1)
}
