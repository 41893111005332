.breadcrumbs {
    display: -webkit-box;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;

    padding-top: 48px;
    padding-bottom: 40px;
    color: #554937;
}

.breadcrumbs-item:first-child {
    margin-left: 0;
}

.breadcrumbs-item:last-child {
    margin-right: 0;
    color: rgba(85,73,55,.5);
}

.breadcrumbs-item--inactive {
    color: rgba(85,73,55,.5) !important;
}

.breadcrumbs-item {
    position: relative;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    align-items: flex-start;
    display: -webkit-inline-box;
    display: -webkit-inline-flex;
    display: inline-flex;
    max-width: 100%;
    margin: 0 8px;
    font-size: .875rem;
    font-weight: 500;
    line-height: 1.6;
    color: #3b3a39;
}

.breadcrumbs-item:not(:first-child):before {
    content: "";
    width: 6px;
    height: 20px;
    -webkit-flex-shrink: 0;
    flex-shrink: 0;
    margin-left: 0;
    margin-right: 16px;
    background-image: url("../../../img/icons/breadcrumbs-angle-right.svg");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: 50%;
}

.breadcrumbs-item a {
    position: relative;
    color: inherit;
}