
.product__counter {
    grid-area: counter;
    position: relative;
}

.product__counter--wrapper {
    width: 104px;
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 39px;
    border: 1px solid #639d00;
}

.product__counter--count,
.product__counter--plus,
.product__counter--minus {
    width: 32px;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items:center;
    color: #83ba26;
    font-weight: 700;
    font-size: 1rem;
    cursor: pointer;
    transition: all .3s;
}
.product__counter--count {
    border-left: 1px solid #639d00;
    border-right: 1px solid #639d00;
    box-sizing: border-box;
    width: 100%;
}

.product__counter--plus,
.product__counter--minus {
    min-width: 32px;
}

.product__counter--plus:hover,
.product__counter--minus:hover
{
    background: linear-gradient(180deg, #9cd836, #83ba26);
    color: #fff;
}

.product__counter--minus {
    border-radius: 39px 0 0 39px;
}

.product__counter--plus {
    border-radius: 0 39px 39px 0;

}

.product-info__button .product__counter--count,
.product-card__button  .product__counter--count {
    height: 48px;
    font-size: 1.1rem;
    font-weight: 500;
}

.product-info__button .product__counter--plus,
.product-info__button .product__counter--minus,
.product-card__button .product__counter--plus,
.product-card__button .product__counter--minus {
    min-width: 56px;
    height: 48px;
    font-size: 24px;
    font-weight: 500;
}


.product-card__button .product__counter--wrapper {
    margin-top: 16px;
    width: 100%;
    height: 48px;
}

.product-info__button .product__counter--wrapper {
    width: 100%;
    height: 48px;
}