
.main-wrapper {
    margin-top: 40px;
    margin-bottom: 120px;
}

.main-section.main-section--banner {
    display: grid;
    grid-template-columns: 320px 1fr;
    grid-gap: 16px;
}

.main-banner {
    min-width: 0;
}

.main-banner__container {
    width: 100%;
    height: 100%;
}

.main-banner__container .banner-slider {
    min-height: 424px;
}

.main-section {
    margin-bottom: 84px;
}

.main-section.main-section--news {
    padding-top: 84px;
    border-top: 1px solid rgba(85,73,55,.2);
}

.main-section.main-section--testimonials {
    padding-top: 84px;
    border-top: 1px solid rgba(85,73,55,.2);
}