.footer {
    padding: 80px 0 40px;
    background-color: #fff;
}

.footer__grid {
    display: grid;
    grid-template-columns: minmax(224px,auto) auto auto 1fr;
    grid-column-gap: 70px;
}

.footer__column.footer__column--first {
    display: flex;
    flex-direction: column;
}

.footer__logo {
    margin-bottom: 29px;
}
.footer__menu--duplicate {
    display: none;
    margin-top: 40px;
}
.footer__menu--duplicate-2,
.footer__buttons--duplicate {
    display: none;
    margin-top: 40px;
}

.footer__logo img {
    width: 224px;
    height: auto;
    -webkit-transform: translateY(-21px);
    transform: translateY(-21px);
}

.footer__contacts-item a {
    white-space: nowrap;
    text-decoration: underline;
}

.footer__contacts-item.footer__contacts-item--phone {
    margin-bottom: 16px;
}

.footer__contacts-item.footer__contacts-item--phone a {
    font-weight: 500;
    color: #83ba26;
    font-size: 1.25rem;
}

.footer__social {
    margin-top: 40px;
    display: flex;
    align-items: center;
}

.footer__social-list {
    display: flex;
    align-items: center;
    margin: -8px;
}

.footer__social-item a {
    display: block;
    padding: 8px;
    -webkit-transition: opacity .25s ease-in-out;
    transition: opacity .25s ease-in-out;
}

.footer__social-item a:hover {
    opacity: .7;
}

.footer__copyright {
    margin-top: auto;
    padding-top: 77px;
}

.footer__copyright span {
    display: block;
    font-size: .875rem;
    color: rgba(85,73,55,.7);
    white-space: nowrap;
    -webkit-transform: translateY(-8px);
    transform: translateY(-8px);
}

.footer__menu.footer__menu--primary .footer__menu-item a {
    font-weight: 500;
}

.footer__menu-item {
    margin: 0 0 16px;
}

.footer__menu-item a {
    text-decoration: underline;
}

.footer__menu-item:last-child {
    margin: 0;
}

.footer__column.footer__column--fourth {
    text-align: right;
}

.footer__buttons .btn {
    width: 100%;
    text-align: center;
}

.footer__buttons .btn + .btn {
    margin-top: 24px;
}

.footer__buttons .btn .btn__icon {
    width: 18px;
}

.footer-copyright {
    display: none;
}