.news-title {
    margin-bottom: 40px;
}

.news-container {
    margin-bottom: 120px;
}

.news-list {
    display: grid;
    grid-template: auto/1fr 1fr;
    grid-gap: 48px;
    grid-row-gap: 120px;
}

.news-card {
    border-radius: 4px;
    overflow: hidden;
}

.news-card__image {
    height: 320px;
    background-color: #f1ece8;
    background-position: center;
    background-size: cover;
}

.news-card__image img {
    object-fit: cover;
    height: 100%;
}

.news-card__content {
    padding-top: 24px;
}

.news-card__date {
    display: block;
    margin-bottom: 16px;
    font-size: 16px;
    line-height: 1.0625;
    opacity: .4;
    color: #554937;
}

.news-card__title {
    display: inline-block;
    font-size: 18px;
    font-weight: 500;
    line-height: 1.6;
    text-decoration: underline;
    -webkit-text-decoration-skip: none;
    text-decoration-skip: none;
    text-decoration-skip-ink: none;
    -webkit-transition: color .2s;
    transition: color .2s;
}

.news-card__preview {
    margin-top: 18px;
    font-size: 16px;
    line-height: 1.6;
}
