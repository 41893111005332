.cart-container {
    margin-top: 40px;
    margin-bottom: 120px;
}

.cart-layout {
    display: grid;
    grid-template-columns: 1fr 352px;
    grid-column-gap: 80px;
}

.cart-title .title h2 {
    margin-bottom: 32px;
    line-height: 1.09;
    font-size: 2rem;
    font-weight: 500;
}

.cart-products__unavailable {
    font-size: 1.375rem;
    line-height: 1.3;
}


.cart-products__unavailable strong {
    font-size: 22px;
    font-weight: 500;
}

.cart-product {
    display: grid;
    grid-template-columns: 120px 1fr 104px 130px 40px;
    grid-template-areas: "image name counter total button";
    grid-column-gap: 16px;
    -webkit-box-align: center;
    -webkit-align-items: center;
    align-items: center;
    padding: 16px 36px 16px 16px;
    background-color: #fff;
    border-radius: 4px;
    box-shadow: 0 4px 10px rgba(85,73,55,.1);
    line-height: 1.2;
}

.cart-product__image {
    grid-area: image;
}

.cart-product__title {
    grid-area: name;
    max-width: 240px;
}
.cart-product__title a {
    font-size: 1rem;
    font-weight: 500;
    color: #554937;
}


.cart-product__price {
    grid-area: total;
    justify-self: flex-end;
    padding-right: 20px;
    font-size: 1.125rem;
    font-weight: 700;
    color: #554937;
}

.cart-product + .cart-product {
    margin-top: 16px;
}


.cart-product__clear {
    color: rgba(85,73,55,.3);
    grid-area: button;
    height: 32px;
    width: 32px;
    cursor: pointer;
    -webkit-transition: color .25s ease-in-out;
    transition: color .25s ease-in-out;
}
.cart-product__clear:hover {
    color: #f28f14;
}
.cart-products__unavailable {
    margin-top: 32px;
    padding-top: 24px;
    border-top: 1px solid rgba(85,73,55,.2);
    margin-bottom: 16px;
}

.cart-shops {
    height: 100%;
    display: -webkit-box;
    display: -webkit-flex;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    flex-direction: column;
    max-height: 800px;
    background-color: #fff;
    z-index: 1;
}

.cart-shops__heading {
    flex-shrink: 0;
    padding: 16px 24px;
    border-bottom: 1px solid rgba(85,73,55,.2);
    line-height: 1.3;
}

.cart-shops__heading-title {
    margin-bottom: 9px;
    font-size: 1rem;
    color: #f28f14;
    font-weight: 500;
}

.cart-shops__heading-subtitle {
    font-size: .875rem;
    color: #554937;
}

.cart-shop {
    border-radius: 4px;
    border: 2px solid #fff;
    box-shadow: 0 4px 10px rgba(85,73,55,.1);
    font-size: .875rem;
    font-weight: 500;
    line-height: 1.3;
    -webkit-transition: border-color .25s ease-in-out;
    transition: border-color .25s ease-in-out;
    margin-bottom: 8px;
    display: -webkit-box;
    display: -webkit-flex;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    align-items: center;
    padding: 16px;
    cursor: pointer;
}
.cart-shop.cart-shop--inactive {
    cursor: default;
}
.cart-shop:not(.cart-shop--inactive):hover {
    border-color: rgba(242,143,20,.5);
}

.cart-shops__body {
    -webkit-box-flex: 1;
    -webkit-flex-grow: 1;
    flex-grow: 1;
    overflow-y: auto;
    padding: 16px;
}

input.cart-shop__checkbox {
    display: inline-block;
    height: 22px;
    width: 22px;
    overflow: hidden;
    vertical-align: middle;
    -webkit-appearance: none;
    -moz-appearance: none;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    -webkit-transition: .25s ease-in-out;
    transition: .25s ease-in-out;
    -webkit-transition-property: box-shadow;
    transition-property: box-shadow;
    background-color: #f9f8f8;
    box-shadow: inset 0 1px 2px rgba(0,0,0,.2);
    border-radius: 20px;
    -webkit-flex-shrink: 0;
    flex-shrink: 0;
    margin-top: 0;
    margin-right: 16px;
    cursor: pointer;
}
.cart-shop.cart-shop--inactive input.cart-shop__checkbox {
    cursor: default;
}
.cart-shop:not(.cart-shop--inactive) input.cart-shop__checkbox:focus,
.cart-shop:not(.cart-shop--inactive) input.cart-shop__checkbox:hover {
    box-shadow: inset 0 0 1px 3px rgba(242,143,20,.5);
    outline: none;
}

input.cart-shop__checkbox:checked {
    background-image: url(data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2222%22%20height%3D%2222%22%20viewBox%3D%220%200%2022%2022%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Ccircle%20cx%3D%2211%22%20cy%3D%2211%22%20r%3D%2211%22%20fill%3D%22%23F28F14%22%2F%3E%3Ccircle%20cx%3D%2211%22%20cy%3D%2211%22%20r%3D%225%22%20fill%3D%22%23fff%22%2F%3E%3C%2Fsvg%3E);
}

.cart-shop__title--address {
    color: #554937;
    margin-bottom: 4px;
}

.cart-shop__title--availability {
    color: rgba(85,73,55,.5);
}

.cart-product__title {
    display: flex;
    flex-direction: column;
}

.cart-products--inactive .cart-product__title span {
    margin-top: 8px;
    font-size: .75rem;
    font-weight: 500;
    line-height: 1.2;
    color: #e26b63;
}

.cart-products--inactive .cart-product__title a {
    color: rgba(85,73,55,.5);
}

.cart-product__title a:hover {
    color: #f28f14
}

.cart-shops__footer {
    -webkit-flex-shrink: 0;
    flex-shrink: 0;
    display: -webkit-box;
    display: -webkit-flex;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    flex-direction: column;
    padding: 24px 16px 32px;
    border-top: 1px solid rgba(85,73,55,.2);
    font-size: 1.125rem;
    line-height: 1.3;
    color: #554937;
}

.cart-shops__sum {
    display: -webkit-box;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    justify-content: space-between;
}

.cart-shops__sum-products {
    display: -webkit-box;
    display: -webkit-flex;
    display: flex;
}

.cart-shops__sum-products strong {
    font-weight: 700;
}

.cart-shops__sum-price {
    font-weight: 700;
}

.cart-shops__button .btn {
    width: 100%;
    height: 48px;
    margin-top: 16px;
}

.cart-product__counter {
    grid-area: counter;
}

.cart-shop__title--availability.available {
    color: #a8ce67;
}

.checkout-modal__block-disclamer {
    margin-top: 32px;
    padding: 16px 32px;
    border: 1px solid rgba(85,73,55,.2);
    border-radius: 4px;
    font-size: 1rem;
    font-weight: 500;
    line-height: 1.5;
    color: #e26b63;
}
