@media (max-width: 1199px) {

    .cart-layout {
        grid-template-columns: 1fr 320px;
        grid-column-gap: 40px;
    }

    .cart-product {
        grid-template-columns: 104px 1fr 104px 100px 40px;
        padding: 16px;
    }

    .cart-shops__sum {
        font-size: 1rem;
    }

    .product-slider__slider-item > * {
        min-height: 460px;
    }

    .header__grid {
        grid-gap: 0 16px;
    }

    .footer__grid {
        grid-column-gap: 40px;
    }

    .checkout-modal__title {
        padding-left: 56px;
    }

    .checkout-modal__inner {
        grid-template-columns: 5fr 4fr;
        grid-column-gap: 48px;
        padding: 56px;
    }

    .checkout-modal__block .checkout-modal__block-summary {
        padding: 24px;
    }

    .checkout-modal__block.checkout-modal__block--order {
        padding-bottom: 0;
    }
}

@media (max-width: 1089px) {
    .category-products {
        grid-template-columns: repeat(3, 1fr)
    }
}

@media (max-width: 1000px) {
    .cart-layout {
        grid-column-gap: 32px;
    }

    .cart-product {
        grid-template-columns: 96px 1fr 40px;
        grid-template-rows: -webkit-max-content auto auto;
        grid-template-rows: max-content auto auto;
        grid-template-areas: "image name button" "image total button" ". counter counter";
        grid-row-gap: 8px;
        -webkit-box-align: start;
        -webkit-align-items: flex-start;
        align-items: flex-start;
        padding: 16px 16px 24px;
    }

    .checkout-modal__inner {
        grid-template-columns: 1fr;
        grid-template-areas: "personal" "shipping" "bonuses" "payment" "info" "footer";
    }

    .checkout-modal__block-body .form {
        max-width: 100%;
    }

    .checkout-modal__block.checkout-modal__block--order {
        margin-top: 20px;
    }

    .checkout-modal__order-button .btn .btn__text {
        font-size: 16px;
    }

    .checkout-modal__order-button .btn {
        width: 100%;
        font-size: 16px;
    }

    .cart-product__price {
        justify-self: flex-start;
    }

    .footer__column.footer__column--second {
        display: none;
    }

    .footer__menu--duplicate {
        display: block;
    }

    .testimonials-main__grid {
        grid-gap: 24px;
    }

    .news-card__image {
        height: 240px;
    }

    .main-wrapper {
        margin-bottom: 12px;
    }

    .main-section {
        margin-bottom: 48px;
    }

    .title h2 {
        font-size: 2.125rem;
    }

    .header-sticky__menu {
        display: block;
    }

    .header {
        display: none;
    }

    .main {
        padding-top: 80px;
    }

    .header-sticky {
        transform: none;
        top: 0;
        min-height: unset;
    }

    .header-sticky__catalog {
        display: none;
    }

    .header-sticky__grid {
        display: grid;
        grid-template-columns: repeat(2, auto) 1fr auto;
        grid-template-areas: "menu logo search buttons";
        grid-column-gap: 24px;
        padding: 16px 0;
    }

    .header-sticky__grid > div {
        margin-top: unset;
    }

    .header-sticky__buttons .btn-green--outline {
        display: none;
    }

    .main-section {
        margin-bottom: 64px;
    }

    .footer__grid {
        grid-template-columns: minmax(224px, auto) auto 1fr;
        grid-column-gap: 70px
    }
}

@media (max-width: 900px) {
    .checkout-modal__title {
        padding-left: 40px;
    }

    .checkout-modal__inner {
        padding: 40px;
    }

    .cart-product {
        grid-template-columns: 88px 1fr 40px;
    }

    .footer__column.footer__column--third {
        display: none;
    }

    .footer__menu--duplicate-2 {
        display: block;
        text-align: left;
    }

    .title h2 {
        font-size: 1.75rem;
    }

    .main-section.main-section--banner {
        grid-template-columns: 240px 1fr;
    }

    .category-products {
        grid-gap: 8px
    }

    .testimonials-main__grid {
        grid-template-columns: 1fr;

    }

    .news-card__image {
        height: 220px;
    }

    .footer__grid {
        grid-template-columns: minmax(224px, auto) 1fr
    }
}

@media (max-width: 780px) {
    .category-products {
        grid-template-columns: repeat(2, 1fr)
    }

    .cart-layout {
        grid-template-columns: 1fr;
        grid-row-gap: 32px;
    }
}

@media (max-width: 640px) {
    section.not-found {
        margin: 0 55px;
        height: calc(100vh - 160px);
        display: flex;
    }
    .order-details__row {
        grid-template-columns: 1fr auto;
    }

    .order-details__row {
        grid-template-columns: 1fr auto;
    }

    .order-products__col.order-products__col--picture {
        grid-template-columns: 1fr;
        grid-gap: 20px;
    }

    .personal-title .title {
        font-size: 24px;
    }

    .personal-section__title {
        font-size: 14px;
    }

    .personal-orders__row--header {
        padding: 0 24px 16px;
    }
    .order-products__col.order-products__col--picture {
        grid-template-columns: 1fr;
        grid-gap: 20px;
    }

    a.personal-orders__row--order {
        grid-template-columns: .7fr 1fr .8fr;
    }

    .personal-orders__col:nth-child(2) {
        display: none;
    }

    .personal-orders__col:nth-child(3) {
        display: none;
    }

    .personal-orders__col:nth-child(2), .personal-orders__col:nth-child(3) {
        display: none;
    }

    .personal-orders__row--header {
        grid-template-columns: 0.7fr 1fr .8fr;
    }

    .personal-section__title {
        display: block;
    }

    .personal-data .personal-section__body {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap :10px
    }

    .personal-data .personal-section__body > input {
        margin: 0;
    }

    .vacancies-inner {
        grid-template-columns: 1fr;
        grid-gap: 12px;
    }

    .vacancies-item {
        padding: 24px;
        font-size: 1rem;
    }

    .vacancies-item__salary {
        font-size: 1rem;
    }
    .modal.open {
        position: relative;
    }

    .checkout-modal__inner {
        padding: 32px;
    }

    .checkout-modal__block.checkout-modal__block--order {
        flex-direction: column;
    }

    .checkout-modal__block-buttons {
        grid-template-columns: 1fr;
    }

    .checkout-modal__order-button {
        margin-top: 20px;
        width: 100%;
    }

    .checkout-modal__order-button .btn {
        width: 100%;
    }

    .checkout-modal__order-button .btn__text {
        font-size: 16px;
    }

    .form-field {
        flex-direction: column;
        width: 100%;
    }

    .form-field__input.col-1-2 {
        width: 100%;
    }

    .form-field__input.col-1-2 + .form-field__input.col-1-2 {
        margin-top: 20px;
    }

    .footer {
        display: none;
    }

    .cart-product__clear, .cart-product__clear svg {
        width: 24px;
        height: 24px;
    }

    .cart-container.container {
        margin-top: 24px;
        margin-bottom: 48px;
    }

    .cart-product__counter .product__counter--wrapper > * {
        height: 24px;
    }

    .cart-title .title h2 {
        font-size: 1.5rem;
    }

    .footer-copyright {
        display: block;
    }

    .title h2 {
        font-size: 1.5rem
    }

    .header-sticky__search {
        display: none;
    }

    .header-sticky__grid {
        display: -webkit-box;
        display: -webkit-flex;
        display: flex !important;
        -webkit-box-pack: justify;
        -webkit-justify-content: space-between;
        justify-content: space-between;
    }

    .main-wrapper {
        margin-bottom: 24px !important;
    }

    .news-card__image {
        height: 160px;
    }

    .news-card__title {
        font-size: 1rem;
    }

    .title {
        justify-content: space-between;
        width: 100%;
    }

    .main-section {
        margin-bottom: 24px;
        border-bottom: 1px solid rgba(85, 73, 55, .2);
        padding-bottom: 24px;
    }

    .main-section.main-section--testimonials,
    .main-section.main-section--news {
        margin-top: 24px;
        padding-top: unset;
        border-top: unset;
    }

    .news-slider__title, .product-slider__title {
        margin-bottom: 24px;
    }

    .main-section.main-section--testimonials {
        border-bottom: unset;
    }

    .breadcrumbs-item:last-child {
        color: initial;
    }

    .header-sticky .btn {
        margin: 0 !important;
    }

    .main-catalog {
        display: none;
    }

    .btn, .btn__text {
        font-size: 12px;
    }

    .product-card__footer {
        padding: 0 14px 14px;
    }

    .product-card__header {
        height: 130px;
    }

    .product-slider__slider-item > * {
        min-height: 330px;
    }

    h3.product-card__title {
        font-size: .875rem;
    }

    .product-card__body {
        padding: 8px 14px;
    }

    .product-card__price {
        font-size: .875rem;
    }

    .sidebar-container {
        width: 100vw;
        left: -100vw;
    }

    .sidebar-back .btn__text {
        font-size: 16px !important;
    }

    .product-slider__title-nav {
        display: none;
    }

    .category-title {
        flex-direction: column;
        align-items: flex-start;
        margin-bottom: 24px;
    }

    .category-sort {
        margin-top: 30px;
        font-size: 14px;
    }

    .breadcrumbs {
        padding-top: 24px;
    }

    .pagination__list {
        display: none;
    }

    .pagination {
        justify-content: center;
        padding-top: 40px;
    }

    .category-container.container {
        margin-bottom: 40px;
    }

    .product-card__title {
        height: 64px;
    }

    .product-card__button .btn {
        padding: 0px;
    }

    .testimonials-list {
        grid-template-columns: 1fr;
        grid-gap: 24px;
    }

    .breadcrumbs-arrow svg {
        width: 20px;
        height: 10px;
        margin-right: 10px;
    }

    .product-info__button .product__counter--plus, .product-info__button .product__counter--minus, .product-card__button .product__counter--plus, .product-card__button .product__counter--minus {
        min-width: 45px;
        font-size: 20px;
    }

    .page-container.container {
        padding: 0 16px;
    }

    .news-list {
        grid-template: auto/1fr;
        grid-row-gap: 48px;
    }

    .news-card {
        border-bottom: 1px solid rgba(85, 73, 55, .2);
        padding-bottom: 24px;
        border-radius: 0;
    }

    .product-main {
        display: flex;
        flex-direction: column;
        padding: 20px;
    }

    .news-container.container {
        margin-bottom: 48px;
    }

    .news-card:last-of-type {
        border: unset;
    }

    .product-main {
        display: flex;
        flex-direction: column;
        padding: 20px;
    }

    h1.product-info__title {
        font-size: 1.75rem;
    }

    span.product-info__code {
        font-size: 0.825rem;
    }

    .product-switch__tab {
        display: block;
        margin: 0;
        border-bottom: 1px solid rgba(85, 73, 55, .2);
    }

    .product-switch__tab:after {
        display: none;
    }

    .product-switch__tab-header {
        display: grid;
        grid-template-columns: 30px 1fr 14px;
        align-items: center;
    }

    .product-switch__tab-caret {
        /* justify-self: flex-end; */
        font-size: 22px;
        width: 14px;
    }

    .product-switch__tab-caret img {
        width: 14px;
        height: 14px;
    }

    h1.product-info__title {
        font-size: 1.75rem;
        line-height: 2rem;
    }

    span.product-info__code {
        font-size: 0.825rem;
    }

    .product-switch__content {
        display: block;
        transition: all .5s;
        max-height: 0;
        overflow: hidden;
        position: relative;
    }

    .product-switch__tab.active .product-switch__content {
        max-height: 1000px;
    }

    .product-switch__availability-offer--address {
        font-size: 12px;
    }

    .product-balance {
        width: 20px;
        height: 20px;
    }

    .product-switch__content-title {
        font-size: 1.5rem;
    }

    .product-switch__tab.active .product-switch__tab-content {
        border-top: 1px solid rgba(85, 73, 55, .2);
    }

    .product-switch__tab {
        padding: 0;
    }

    .product-switch__tab-content {
        padding: 0 20px;
    }

    .product-switch__tab-header {
        padding: 20px;
    }

    .product-switch__content-title {
        margin-top: 30px;
    }

    .product-switch__tab.active > *, .product-switch__tab:hover > * {
        color: #554937;
    }

    .product-switch__tab.active > .product-switch__tab-header, .product-switch__tab:hover > .product-switch__tab-header {
        color: #f28f14;
    }

    .product-switch__content-body {
        margin-bottom: 20px;
    }

    .desktop {
        display: none;
    }

    .mobile {
        display: block;
    }

    .main-section.main-section--banner {
        display: block;
    }

    .banner-slider__item-content {
        padding: 20px;
    }

    .banner-slider__item-title {
        font-size: 18px;
    }

    .banner-slider__item {
        min-width: 280px;
        min-height: 136px;
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        border-radius: 5px;
    }

    .main-banner__container {
        overflow: auto;
        display: flex;
    }

    .banner-slider__item + .banner-slider__item {
        margin-left: 20px;
    }

    .banner-slider__item-text {
        display: none;
    }

    .banner-slider__item-content .btn {
        display: none;
    }

    .main-banner__container .banner-slider {
        min-height: 180px;
    }

    .banner-slider__item-content {
    }

    .news-slider__title-nav, .product-slider__title-nav {
        display: none;
    }

    .news-slider__slider-item > *, .product-slider__slider-item > * {
        min-height: 320px;
    }

    .product-info__purchase {
        width: 100%;
    }

    .cart-shops__button .btn__text {
        font-size: 16px;
    }

    .checkout-modal__title {
        padding: 32px;
    }

    .modal.checkout-modal.open {
        top: 10px
    }

    .modal.open {
        max-width: calc(100% - 20px) !important;
    }

    .checkout-modal__title {
        font-size: 1.25rem;
    }

    .checkout-modal__block-title {
        font-size: 1rem;
    }

    .checkout-modal__block-button-descr {
        font-size: 0.825rem;
    }
}

@media (max-width: 425px) {
    .checkout-modal__title {
        padding: 24px;
    }

    .checkout-modal__inner {
        padding: 24px;
    }

}

@media (max-width: 370px) {

    .main-section {
        margin-bottom: 24px;
    }

    .category-products {
        grid-template-columns: 1fr
    }
}
