.container {
    display: flow-root;
    box-sizing: content-box;
    max-width: 1136px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 16px;
    padding-right: 16px;
}


@media (min-width: 900px) {
    .container {
        padding-left: 40px;
        padding-right: 40px;
    }
}
