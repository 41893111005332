.club-image {

    position: relative;
    background: -webkit-linear-gradient(338.52deg, rgba(85, 73, 55, .9) 43.21%, rgba(85, 73, 55, 0)), url("../../../../static/img/club-main.jpg");
    background: linear-gradient(-248.52deg, rgba(85, 73, 55, .9) 43.21%, rgba(85, 73, 55, 0)), url("../../../../static/img/club-main.jpg");
    background-repeat: no-repeat;
    padding: 100px 48px;
    border-radius: 3px;
    color: #fff;
    margin-bottom: 120px;
}

.club-image__title {
    font-size: 2.5rem;
    font-weight: 500;
}

.club-image__text {
    font-size: 1.5rem;
    line-height: 1.3;
}

.club-selector {
    display: flex;
    width: 560px;
    margin: 0 auto;
    background: #f1efee;
    border-radius: 50px
}


.club-selector__button--icon {
    filter: grayscale(1);
    margin-top: -7px;
    margin-right: 13px;
}

.club-selector__button.active {
    background: #fff;
}

.club-selector__button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    padding: 22px 12px;
    color: #9b9b9b;
    font-weight: 500;
    font-size: 1.125rem;
    line-height: 1.12;
    cursor: pointer;
    background-color: transparent;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    z-index: 2;
    border: none;
    outline: none;
    border-radius: 999px;
    -webkit-transition: background-color .25s;
    transition: background-color .25s;
}


.club-selector__button.active .club-selector__button--text {
    color: #f28f14;
}

.club-selector__button.active .club-selector__button--icon {
    filter: grayscale(0);
}

.club-block {
    margin: 80px 0;
}
.club-advice {
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    align-items: center;
    padding: 24px 32px;
    font-size: 1.25rem;
    line-height: 1.2;
    background: #fff;
    border-radius: 4px;
    box-shadow: 0 2px 4px rgb(85 73 55 / 10%);
    -webkit-transition: color .3s,box-shadow .3s;
    transition: color .3s,box-shadow .3s;
}

.club-advice:hover {
    text-decoration: underline;
    box-shadow: 0 0 20px rgb(85 73 55 / 20%);
}

.club-advice + .club-advice {
    margin-top: 5px;
}

.club-galleries {
    display: grid;
    grid-template: auto/1fr 1fr;
    grid-gap: 48px;
}

.club-gallery {
    background-color: #fff;
    box-shadow: 0 4px 20px rgb(85 73 55 / 10%);
    -webkit-transition: box-shadow .25s;
    transition: box-shadow .25s;
}

.club-gallery:hover {
    box-shadow: 0 4px 30px rgb(85 73 55 / 20%);
}

.club-gallery__image {
    display: block;
    position: relative;
    overflow: hidden;
    padding-top: 58.82353%;
    background-color: #dad5d2;
    background-size: cover;
    background-position: center;
}

.club-gallery__text {
    padding: 24px 32px;
    color: #554937;
    text-underline: none;
}

.club-gallery__text div:nth-child(1) {
    display: block;
    margin-bottom: 1rem;
    font-size: 1rem;
    line-height: 1.0625;
    opacity: .4;
}

.club-gallery__text div:nth-child(2) {
    display: inline-block;
    font-size: 1.125rem;
    font-weight: 500;
    line-height: 1.6;
    -webkit-transition: color .2s;
    transition: color .2s;
}

