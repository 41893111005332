:root {
    --headerSize:96px}

@media (max-width: 999px) {
    :root {
        --headerSize:80px
    }
}

*,:after,:before {
    box-sizing: border-box
}

ol,ul {
    padding: 0;
    list-style: none
}

blockquote,body,dd,dl,figcaption,figure,h1,h2,h3,h4,li,ol,p,ul {
    margin: 0
}

html {
    font-size: 16px;
    -webkit-tap-highlight-color: transparent;
    scroll-behavior: smooth;
    font-family: "Blogger Sans", sans-serif;
    color: #554937;
    font-feature-settings: 'pnum' on, 'lnum' on;
}

body {
    margin: 0;
    min-height: 100vh;
    text-rendering: optimizeSpeed;
    line-height: 1.5;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background: #f9f8f8;
}

a:not([class]) {
    text-decoration-skip-ink: auto
}

img {
    max-width: 100%;
    display: block
}

button,input,select,textarea {
    font: inherit
}

@media (prefers-reduced-motion:reduce) {
    * {
        -webkit-animation-duration: .01ms!important;
        animation-duration: .01ms!important;
        -webkit-animation-iteration-count: 1!important;
        animation-iteration-count: 1!important;
        -webkit-transition-duration: .01ms!important;
        transition-duration: .01ms!important;
        scroll-behavior: auto!important
    }
}

textarea {
    resize: vertical
}

iframe {
    max-width: 100%
}

fieldset {
    min-width: 0
}

th {
    font-weight: inherit;
    text-align: inherit
}

a {
    color: #554937;
    text-decoration: none;
    cursor: pointer;
    color: inherit;
    text-decoration: inherit;
    -webkit-transition: color .25s ease-in-out;
    transition: color .25s ease-in-out;
}

a:hover {
    color: #f28f14;
    text-decoration: none;
}

.bold {
    font-weight: 500;
}
.black {
    font-weight: 700;
}

.stroke {
    text-decoration: underline;
}

.lazy-load-image-background img {
    height: 100%;
    text-align: center;
    margin: 0 auto;
}


.hamburger {
    display: -webkit-box;
    display: -webkit-flex;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    align-items: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    width: 18px;
    height: 18px
}

.hamburger__icon:after,.hamburger__icon:before {
    content: " "
}

.hamburger__icon,.hamburger__icon:after,.hamburger__icon:before {
    display: block;
    position: relative;
    width: 100%;
    height: 2px;
    background-color: #fff;
    border-radius: 6px
}

.hamburger__icon:after {
    top: 4px
}

.hamburger__icon:before {
    top: -6px
}

.mobile {
    display: none;
}