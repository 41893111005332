.main-banner__container .banner-slider {
    width: 100%;
    height: 100%;
}

.swiper-slide .banner-slider__item {
    background-position: center;
    background-size: cover;
    border-radius: 5px;
    height: 100%;
    width: 100%;
    background-color: #f1ece8;
}

.banner-slider__item-content {
    width: 100%;
    height: 100%;
    position: relative;
    padding: 48px 48px 56px;
    text-shadow: 0 4px 20px #554937;
    color: #fff;
}

.banner-slider__item-title {
    font-weight: 700;
    font-size: 2.5rem;
    line-height: 1.2;
}

.banner-slider__item-content .btn {
    text-shadow: none;
    position: absolute;
    bottom: 40px;
}

.banner-slider .swiper-pagination-bullet {
    width: 8px;
    height: 8px;
    margin: 10px !important;
    cursor: pointer;
    background: #fff;
    opacity: 1;
}

.banner-slider .swiper-pagination-bullet-active {
    cursor: pointer;
    background: #f28f14;
}

.banner-slider .swiper-pagination {
    bottom: 40px;
    width: 300px;
    margin: 0 auto;
    left: 0;
    right: 0;
}