.title {
    display: flex;
    align-items: center;
}

.title h2 {
    line-height: 1.1;
    font-size: 2.5rem;
    font-weight: 500;
}

.title h2 + .btn {
    margin-left: 16px;
}