.sidebar {
    width: 100vw;
    touch-action: none;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    z-index: 1020;
}

/*.sidebar::before {*/
    /*content: "";*/
    /*position: absolute;*/
    /*top: 0;*/
    /*bottom: 0;*/
    /*left: 0;*/
    /*right: 0;*/
    /*background: rgba(0, 0, 0, .1);*/
    /*opacity: 0;*/
    /*-webkit-transition: opacity .15s linear;*/
    /*transition: opacity .15s linear;*/
/*}*/


.sidebar.open .sidebar-container {
    left: 0;
}

.sidebar-container {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 375px;
    left: -375px;
    box-sizing: border-box;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    -webkit-transition: left .3s ease-out;
    transition: left .3s ease-out;
    background-color: #f9f8f8;
    overflow-x: hidden;
}

/*City*/

.sidebar-top {
    display: -webkit-box;
    display: -webkit-flex;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-flow: row nowrap;
    flex-flow: row nowrap;
    -webkit-box-align: center;
    -webkit-align-items: center;
    align-items: center;
    padding: 16px;
    background-color: #fff;
}

.sidebar-close .btn {
    padding: 17px;
}
.sidebar-personal .btn {
    padding: 15px;
}

.sidebar-city {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 auto;
    flex: 1 1 auto;
    font-weight: 500;
    text-decoration: underline;
    position: relative;
    margin-left: 16px;
}

.sidebar-search {
    position: relative;
    background: white;
    padding: 10px 10px;
    border-top: 1px solid #e9e9e9;
}

.sidebar-city__city{
    display: flex;
    align-items: center;
}

.sidebar-city__city-value {
    display: flex;
    -webkit-transition: opacity .25s ease-in-out;
    transition: opacity .25s ease-in-out;
    align-items: center;
    cursor: pointer;
}

.sidebar-city__city-value:hover {
    opacity: 0.5;
}

.sidebar-city__city-value--popup,
.sidebar-city__city-value--selector {
    position: absolute;
    left: -60px;
    top: 40px;
    visibility: hidden;
    z-index: 1000;
    opacity: 0;
    padding: 27px 32px 25px;
    border: 1px solid #f1efee;
    border-radius: 4px;
    background-color: #fff;
    box-shadow: 0 20px 40px rgb(0 0 0 / 25%);
    transform: translateY(-20px);
    transition: visibility .25s ease-in-out,opacity .25s ease-in-out,transform .25s ease-in-out;
    min-width: 320px;
}

.sidebar-city__city-value--popup:before,
.sidebar-city__city-value--selector:before {
    content: "";
    display: block;
    position: absolute;
    left: 100px;
    top: -11px;
    z-index: 10;
    width: 20px;
    height: 20px;
    border-top: 1px solid #f1efee;
    border-left: 1px solid #f1efee;
    background-color: #fff;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg)
}

.sidebar-city__city-value--popup.active,
.sidebar-city__city-value--selector.active {
    visibility: visible;
    opacity: 1;
    -webkit-transform: none;
    transform: none
}

.sidebar-city__city-value--selector-item {
    cursor: pointer;
    font-weight: 500;
    line-height: 17px;
    -webkit-transition: opacity .25s ease-in-out;
    transition: opacity .25s ease-in-out
}

.sidebar-city__city-value--selector-item + .sidebar-city__city-value--selector-item {
    margin-top: 8px;
}

.sidebar-city__city-value--selector-item.active {
    text-decoration: underline
}

.sidebar-city__city-value--selector-item:focus,.sidebar-city__city-value--selector-item:hover {
    opacity: .5
}

.sidebar-city__city-value--popup_buttons .btn-orange {
    height: 32px;
    margin-right: 14px
}

.sidebar-city__city-value--popup_buttons .btn-orange--outline {
    height: 32px
}


.sidebar-city__city-value--popup_buttons {
    display: flex;
    margin-top: 20px;
}

.sidebar-city__city-value--popup .text {
    font-weight: 500;
}

.sidebar-city__city-value--icon {
    margin-left: 8px;
}

.sidebar-navigation {
    display: -webkit-box;
    display: -webkit-flex;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    align-items: center;
    height: 72px;
    padding-left: 24px;
}

.sidebar-phone {
    display: -webkit-box;
    display: -webkit-flex;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    align-items: center;
    color: #83ba26;
    -webkit-transition: -webkit-transform .25s ease-in-out;
    transition: -webkit-transform .25s ease-in-out;
    transition: transform .25s ease-in-out;
    transition: transform .25s ease-in-out,-webkit-transform .25s ease-in-out;
}

.sidebar-phone a {
    margin-left: 27px;
    font-size: 18px;
    font-weight: 700;
    line-height: 20px;
    -webkit-transition: -webkit-transform .25s ease-in-out;
    transition: -webkit-transform .25s ease-in-out;
    transition: transform .25s ease-in-out;
    transition: transform .25s ease-in-out,-webkit-transform .25s ease-in-out;
}


.sidebar-menu__primary {
    padding: 24px;
}

.sidebar-menu__secondary {
    padding: 24px;
    background-color: #fff;
}

.sidebar-footer {
    padding: 32px 24px;
}

ul.sidebar-footer__social-list {
    display: flex;
    display: -webkit-box;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    margin: -8px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
}

li.sidebar-footer__social-item {
    -webkit-flex-shrink: 0;
    flex-shrink: 0;
}

li.sidebar-footer__social-item a {
    display: block;
    padding: 8px;
    -webkit-transition: opacity .25s ease-in-out;
    transition: opacity .25s ease-in-out;
}

.sidebar-footer__icons {
    margin-bottom: 32px;
}

.sidebar-footer .btn {
    width: 100%;
}

.sidebar-footer .btn + .btn {
    margin-top: 20px;
}


.sidebar-copyright {
    padding: 24px 20px 20px;
    border-top: 1px solid rgba(85,73,55,.2);
}

.sidebar-copyright {
    display: -webkit-box;
    display: -webkit-flex;
    display: flex;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    /* align-items: flex-start; */
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    justify-content: space-between;
    align-items: center;
}

.sidebar-copyright__logo {
    width: 77px;
    height: auto;
    -webkit-transform: translateY(-3px);
    transform: translateY(-3px);
    filter: grayscale(1);
}

.sidebar-copyright__text span {
    font-size: .75rem;
    white-space: normal;
    -webkit-transform: none;
    transform: none;
    color: rgba(85,73,55,.7);
}

.sidebar-back .btn {
    color: #333;
}

.sidebar-back .btn__icon {
    transform: rotate(90deg);
}