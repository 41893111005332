.actions-list {
    display: grid;
    grid-template: auto/1fr 1fr;
    grid-gap: 48px;
}

.actions-title {
    margin-bottom: 40px;
}

.actions-wrapper {
    margin-bottom: 120px;
}

.action-card {
    cursor: pointer;
    border-radius: 4px;
    overflow: hidden;
    background-color: #fff;
    box-shadow: 0 4px 20px rgba(85,73,55,.1);
    -webkit-transition: box-shadow .25s;
    transition: box-shadow .25s;
}

.action-card:hover {
    box-shadow: 0 4px 30px rgba(85,73,55,.2);
}

.action-card__image {
    height: 320px;
    background-color: #f1ece8;
    background-position: center;
    background-size: cover;
    position: relative;
}

.action-card__image img {
    object-fit: cover;
    height: 100%;
}

.action-card__content {
    padding: 24px 32px;
    color: #554937;
}

.action-card__date {
    opacity: 1;
    bottom: 0;
    margin-bottom: 0;
    position: absolute;
    left: 0;
    padding: 8px 32px;
    color: #fff;
    font-size: 1rem;
    font-weight: 700;
    line-height: 1.0625;
    background-color: rgba(131,186,38,.8);
    border-top-right-radius: 4px;
}

.action-card__title {
    display: inline-block;
    font-size: 18px;
    font-weight: 500;
    line-height: 1.6;
    text-decoration: underline;
    -webkit-text-decoration-skip: none;
    text-decoration-skip: none;
    text-decoration-skip-ink: none;
    -webkit-transition: color .2s;
    transition: color .2s;
}

.action-card__preview {
    margin-top: 18px;
    font-size: 16px;
    line-height: 1.6;
}

.action-card__more {
    display: flex;
    margin-top: auto;
    padding-top: .44444em;
    font-size: 1.125rem;
    color: #83ba26;
    line-height: 1.6;
}
.action-card__more--text {
    text-decoration: underline;
}

.action-card__more--icon {
    margin-left: 8px;
}