.category-container {
    margin-bottom: 120px;
}

.category-products {
    display: grid;
    grid-template-columns: repeat(4,1fr);
    grid-gap: 16px;
}

h3.product-card__title {
    box-sizing: content-box;
    overflow: hidden;
}

.category-title h2 {
    font-size: 2rem;
    font-weight: 500;
}

.category-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 40px;
}

.category-sort--label {
    font-weight: bold;
}

.category-sort--value {
    border-bottom: 1px solid #554937;
    -webkit-transition: border-color .25s ease-in-out;
    transition: border-color .25s ease-in-out;
    cursor: pointer;
}


.category-sort {
    position: relative;
}

.category-sort--dropdown {
    display: none;
    position: absolute;
    right: 0;
    width: 100%;
    border-radius: 4px;
    box-shadow: 0 2px 20px rgb(85 73 55 / 10%);
    z-index: 100;
    box-sizing: border-box;
    min-width: 184px;
    padding: 16px;
    background: #fff;
    color: #666;
    top: 30px;
}

.category-sort--dropdown.open {
    display: block;
}

.category-sort--dropdown li:not(:last-of-type) {
    margin-bottom: 8px;
}

.category-sort--dropdown li {
    cursor: pointer;
}

.category-sort--dropdown li.active,
.category-sort--dropdown li:hover {
    color: #f28f14;
    transition: 0.3s;
}

.category-text {
    margin-bottom: 20px;
}

.category-text a {
    text-decoration: underline;
}