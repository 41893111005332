.pagination {
    display: flex;
    align-items: center;
    padding-top: 80px;
    justify-content: flex-end;

}
.pagination__container {
    display: flex;
}
.pagination__list {
    -webkit-box-pack: end;
    -webkit-justify-content: flex-end;
    justify-content: flex-end;
    display: -webkit-box;
    display: -webkit-flex;
    display: flex;
    margin-bottom: 0;
}

.pagination__next:hover,
.pagination__prev:hover {
    color: #f28f14;
    border-color: #f28f14;
    box-shadow: 0 4px 4px rgba(85,73,55,.1);
}

.pagination__next,
.pagination__prev {
    height: 40px;
    min-width: 40px;
    border: 1px solid rgba(85,73,55,.3);
    border-radius: 50%;
    line-height: 1;
    font-weight: 500;
    font-size: 1rem;
    -webkit-transition: color .25s ease-in-out,border-color .25s ease-in-out,background-color .25s ease-in-out;
    transition: color .25s ease-in-out,border-color .25s ease-in-out,background-color .25s ease-in-out;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}
.pagination__prev {
    margin-right: 24px;
}

.pagination__next {
    margin-left: 24px;
}

.pagination__button {
    height: 40px;
    min-width: 40px;
    display: -webkit-box;
    display: -webkit-flex;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    align-items: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    border-radius: 50%;
}

.pagination__button.pagination__button--active {
    background-color: #f28f14;
    color: #fff;
}

.pagination__button div {
    border-radius: 50%;
    line-height: 1;
    font-weight: 500;
    font-size: 1rem;
    -webkit-transition: color .25s ease-in-out,border-color .25s ease-in-out,background-color .25s ease-in-out;
    transition: color .25s ease-in-out,border-color .25s ease-in-out,background-color .25s ease-in-out;
    cursor: pointer;
}
