.personal-orders__row--header {
    display: grid;
    grid-template-columns: 1fr 1fr .9fr 1.2fr .5fr;
    grid-column-gap: 32px;
    padding: 0 32px 16px;
    color: #554937;
    opacity: .5;
}

.personal-orders__row--order
{   display: grid;
    grid-template-columns: 1fr 1fr .9fr 1.2fr .5fr;
    padding: 24px 32px;
    grid-gap: 32px;
    background-color: #fff;
    box-shadow: 0 4px 20px rgb(85 73 55 / 10%);
    -webkit-transition: box-shadow .25s;
    transition: box-shadow .25s;
    cursor: pointer;
    margin-top: 20px;

}

.personal-orders__row--order:hover {
    box-shadow: 0 4px 30px rgb(85 73 55 / 20%);
}


.order-products__row--order
{
    display: grid;
    grid-gap: 32px;
    background-color: #fff;
    box-shadow: 0 4px 20px rgb(85 73 55 / 10%);
    transition: box-shadow .25s;
    cursor: pointer;
    margin-top: 20px;

    grid-template-columns: 2.2fr 1fr 1fr;
    padding: 16px;
    grid-column-gap: 16px;
}

.order-products__row--order:hover {
    box-shadow: 0 4px 30px rgb(85 73 55 / 20%);
}

.order-products__col {
    font-weight: bold;
    align-items: center;
    display: flex;
}


.order-products__col.order-products__col--picture img {
    max-height: 70px;
    width: 120px;
    object-fit: contain;
}

.order-details {
    margin-top: 50px;
}

.order-payment {
    margin-top: 30px;
}

.order-details__title {
    margin-top: 40px;
    margin-bottom: 16px;
    font-size: 1.375rem;
    font-weight: 500;
    line-height: 1.3;
    color: #554937;
}

.order-products__col--picture {
    font-size: 1rem;
    display: grid;
    grid-template-columns: 120px auto;
    -webkit-box-align: center;
    -webkit-align-items: center;
    align-items: center;
    grid-column-gap: 16px;
    font-weight: 500;
}

.personal-data .personal-section__body {
    display: flex;
    justify-content: space-between;
}

.personal-data .personal-section__body > input {
    margin: 0 10px;
}

.personal-data .personal-section__body > input:first-of-type {
    margin: 0 10px 0 0;
}

.personal-container.container {
    margin-top: 40px;
    margin-bottom: 120px;
}

.personal-section {
    margin-top: 40px;
}

.personal-section__title {
    margin-bottom: 24px;
    font-size: 22px;
}

.personal-section__body {
    margin-bottom: 20px;
}


.personal-title {
    display: flex;
    justify-content: space-between;
}

.personal-section.personal-data {
    margin-bottom: 50px;
}

.personal-container.container hr {
    opacity: .3;
}

.order-details__row {
    display: grid;
    margin-bottom: 18px;
    grid-template-columns: 348px auto;
}

.order-details__row--key {
    display: grid;
    grid-template-columns: -webkit-max-content auto;
    grid-template-columns: max-content auto;
    color: rgba(85,73,55,.5);
}

.order-details__row--key:after {
    content: "";
    border-bottom: 1px dashed rgba(85,73,55,.2);
    position: relative;
    bottom: 0.4rem;
    margin: 0 8px;
}

.order-details__row--value {
    font-weight: 500;
}

.personal-data .personal-section__body > input:disabled {
    background: #e1e1e1;
    cursor: not-allowed;
}

.personal-section__title {
    justify-content: space-between;
}

.personal-section__title--bonuses {
    font-size: 16px;
}

.personal-section__title--bonuses span {
    font-weight: bold;
}