@font-face {
    font-display: swap;
    font-family: 'Blogger Sans';
    font-style: normal;
    font-weight: 400;
    src: url('BloggerSans.woff') format('woff'),
    url('BloggerSans.woff2') format('woff2'),
    url('BloggerSans.ttf') format('ttf');
}

@font-face {
    font-display: swap;
    font-family: 'Blogger Sans';
    font-style: normal;
    font-weight: 500;
    src: url('BloggerSans-Medium.woff') format('woff'),
    url('BloggerSans-Medium.woff2') format('woff2'),
    url('BloggerSans-Medium.ttf') format('ttf');
}

@font-face {
    font-display: swap;
    font-family: 'Blogger Sans';
    font-style: normal;
    font-weight: 700;
    src: url('BloggerSans-Bold.woff') format('woff'),
    url('BloggerSans-Bold.woff2') format('woff2'),
    url('BloggerSans-Bold.ttf') format('ttf');
}
