.swiper-container {
    margin-left: auto;
    margin-right: auto;
    position: relative;
    overflow: hidden;
    list-style: none;
    padding: 0;
    z-index: 1;
}


.swiper-button-disabled > .btn {
    cursor: default !important;
    border: 1px solid #ccc;
    box-shadow: none;
    color: gray;
    background: transparent;
    opacity: 0.3;
}

.swiper-button-disabled > .btn:hover {
    border: 1px solid #ccc;
    box-shadow: none;
    color: gray;
    background: transparent;
    opacity: 0.3;
}

.news-slider__title,
.product-slider__title {
    margin-bottom: 40px;
    display: flex;
    justify-content: space-between;
}

.news-slider__title-nav,
.product-slider__title-nav {
    display: flex;
}

.news-slider__title-nav> *+*,
.product-slider__title-nav> *+* {
    margin-left: 20px;
}


.news-slider__slider-item > :not(:last-child),
.product-slider__slider-item > :not(:last-child) {
    margin-bottom: 16px;
}

.news-slider__slider-item > *,
.product-slider__slider-item > * {
    min-height: 405px;
    height: auto;
}