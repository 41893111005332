
.vacancies-inner {
    display: grid;
    grid-template-columns: 66.5% 29%;
    grid-gap: 48px;
    margin: 48px 0;
}

.vacancies-contacts {
    display: flex;
    flex-direction: column;
}
.vacancies-contact {
    margin-top: 48px;
    background-color: #fff;
    box-shadow: 0 4px 20px rgb(85 73 55 / 10%);
    font-weight: 500;
    padding: 24px 32px;
    color: #554937;
}

.vacancies-contact__label {
    font-weight: 400;
    opacity: .5;
}

.vacancies-contact__title {
    font-size: 1.375rem;
    margin-bottom: 24px;
    font-weight: 400;
}

.vacancies-contact__phone {
    margin-bottom: 16px;
}


.vacancies-inner {
    display: grid;
    grid-template-columns: 66.5% 29%;
    grid-gap: 48px;
    margin: 48px 0;
}

.vacancies-item  {
    background: #fff;
    margin-top: 20px;
    background-color: #fff;
    box-shadow: 0 4px 20px rgb(85 73 55 / 10%);
    -webkit-transition: box-shadow .25s;
    transition: box-shadow .25s;
}

.vacancies-item .ReactCollapse--collapse {
    transition: all .3s;
}

.vacancies-item .vacancies-item-description {
    padding: 0 32px 0 48px;
    transition: all .3s;
    position: relative;
}

.vacancies-item .vacancies-item-description:before {
    content: '';
    width: 0;
    height: 2px;
    background: #f19137;
    position: absolute;
    top: 0;
    left: 0;
    transition: all .3s;
}

.vacancies-item .vacancies-item-description.active {
    padding: 32px 32px 32px 48px;
}

.vacancies-item .vacancies-item-description.active:before {
    width: 100%;
}

.vacancies-item-heading {
    padding: 32px 32px 32px 48px;
    font-weight: 500;
    cursor: pointer;
    display: -webkit-box;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    justify-content: space-between;
    position: relative;
    font-size: 1.375rem;
}

.vacancies-item-description .vacancy-description__section {
    margin-top: 20px;
}

.vacancy-description__title {
    font-weight: bold;
}

.vacancies-list {
    margin-bottom: 48px;
}

.vacancies-item__salary {
    font-size: 20px;
}