.header {
    width: 100%;
    min-height: 240px;
    background-color: #fff;
    box-shadow: 0 4px 20px rgba(85, 73, 55, .1);
    position: relative;
    z-index: 1000;
}


.header-top__wrapper {
    height: 56px;
    border-bottom: 1px solid rgba(85, 73, 55, .2);
    line-height: 17px;
    align-items: center;
}

.header-top__grid {
    height: 56px;
    display: grid;
    grid-template-columns: auto auto 1fr auto;
    grid-template-areas: "city phone social feedback";
    grid-gap: 0 24px;
}

.header-top__social {
    display: flex;
    align-items: center;
}

.header-top__social-list {
    display: flex;
    align-items: center;
}

.header-top__feedback-button {
    display: flex;
    align-items: center;
}

.header-top__city {
    position: relative;
}

.header-top__city, .header-top__phone, .header-top__feedback {
    display: flex;
    align-items: center;
}

.header-top__city-value {
    display: flex;
    -webkit-transition: opacity .25s ease-in-out;
    transition: opacity .25s ease-in-out;
    align-items: center;
    cursor: pointer;
}

.header-top__city-value:hover {
    opacity: 0.5;
}
.catalog-button {
    position: relative;
}
.catalog-button--menu {
    position: absolute;
    left: 0px;
    top: 65px;
    visibility: hidden;
    z-index: 1000;
    opacity: 0;
    min-width: 300px;
    border: 1px solid #f1efee;
    border-radius: 4px;
    -webkit-transform: translateY(-20px);
    transform: translateY(-20px);
    -webkit-transition: visibility .25s ease-in-out,opacity .25s ease-in-out,-webkit-transform .25s ease-in-out;
    transition: visibility .25s ease-in-out,opacity .25s ease-in-out,-webkit-transform .25s ease-in-out;
    transition: visibility .25s ease-in-out,opacity .25s ease-in-out,transform .25s ease-in-out;
    transition: visibility .25s ease-in-out,opacity .25s ease-in-out,transform .25s ease-in-out,-webkit-transform .25s ease-in-out
}

.header-menu__list-more--selector {
    position: absolute;
    left: -20px;
    top: 50px;
    visibility: hidden;
    z-index: 1000;
    opacity: 0;
    min-width: 270px;
    padding: 27px 32px 25px;
    border: 1px solid #f1efee;
    border-radius: 4px;
    background-color: #fff;
    box-shadow: 0 20px 40px rgba(0,0,0,.25);
    -webkit-transform: translateY(-20px);
    transform: translateY(-20px);
    -webkit-transition: visibility .25s ease-in-out,opacity .25s ease-in-out,-webkit-transform .25s ease-in-out;
    transition: visibility .25s ease-in-out,opacity .25s ease-in-out,-webkit-transform .25s ease-in-out;
    transition: visibility .25s ease-in-out,opacity .25s ease-in-out,transform .25s ease-in-out;
    transition: visibility .25s ease-in-out,opacity .25s ease-in-out,transform .25s ease-in-out,-webkit-transform .25s ease-in-out
}
.header-top__city-value--popup,
.header-top__city-value--selector{
    position: absolute;
    left: 50px;
    top: 50px;
    visibility: hidden;
    z-index: 1000;
    opacity: 0;
    min-width: 200px;
    padding: 27px 32px 25px;
    border: 1px solid #f1efee;
    border-radius: 4px;
    background-color: #fff;
    box-shadow: 0 20px 40px rgba(0,0,0,.25);
    -webkit-transform: translateY(-20px);
    transform: translateY(-20px);
    -webkit-transition: visibility .25s ease-in-out,opacity .25s ease-in-out,-webkit-transform .25s ease-in-out;
    transition: visibility .25s ease-in-out,opacity .25s ease-in-out,-webkit-transform .25s ease-in-out;
    transition: visibility .25s ease-in-out,opacity .25s ease-in-out,transform .25s ease-in-out;
    transition: visibility .25s ease-in-out,opacity .25s ease-in-out,transform .25s ease-in-out,-webkit-transform .25s ease-in-out
}

.header-top__city-value--popup:before,
.header-top__city-value--selector:before,
.header-menu__list-more--selector:before{
    content: "";
    display: block;
    position: absolute;
    left: 30px;
    top: -11px;
    z-index: 10;
    width: 20px;
    height: 20px;
    border-top: 1px solid #f1efee;
    border-left: 1px solid #f1efee;
    background-color: #fff;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg)
}

ul.header-menu__list-more--selector-list li {
    margin-bottom: 10px;
}

ul.header-menu__list-more--selector-list li:last-of-type {
    margin-bottom: unset;
}

.catalog-button--menu.active,
.header-top__city-value--popup.active,
.header-top__city-value--selector.active,
.header-menu__list-more--selector.active {
    visibility: visible;
    opacity: 1;
    -webkit-transform: none;
    transform: none
}

.header-top__city-value--selector-item {
    cursor: pointer;
    font-weight: 500;
    line-height: 17px;
    -webkit-transition: opacity .25s ease-in-out;
    transition: opacity .25s ease-in-out
}

.header-top__city-value--selector-item + .header-top__city-value--selector-item {
    margin-top: 8px;
}

.header-top__city-value--selector-item.active {
    text-decoration: underline
}

.header-top__city-value--selector-item:focus,.header-top__city-value--selector-item:hover {
    opacity: .5
}

.header-top__city-value--popup_buttons .btn-orange {
    height: 32px;
    margin-right: 14px
}

.header-top__city-value--popup_buttons .btn-orange--outline {
    height: 32px
}


.header-top__city-value--popup_buttons {
    display: flex;
    margin-top: 20px;
}

.header-top__city-value--popup .text {
    font-weight: 500;
}

.header-top__social-item a {
    display: block;
    padding: 8px;
    -webkit-transition: opacity .25s ease-in-out;
    transition: opacity .25s ease-in-out;
}

.header-top__social-item a:hover {
    opacity: .7;
}

.header-top__city-value,
.header-top__city-value--icon {
    margin-left: 8px;
}

.header-top__feedback-button {
    display: flex;
    align-items: flex-start;
}

.header-top__feedback-button img {
    height: 20px;
    margin-right: 10px;
}

.header__grid {
    display: grid;
    grid-template-columns: minmax(222px, auto) 1fr auto;
    grid-template-areas: "logo search buttons";
    grid-gap: 0 36px;
    align-items: flex-end;
    height: 90px;
}

.header__catalog {
    display: none;
}

.header__search {
    display: -webkit-box;
    display: -webkit-flex;
    display: flex;
    position: relative
}

.header__search-input {
    border: 1px solid #f3f2f1;
    border-radius: 39px;
    background-color: #f3f2f1;
    color: inherit;
    font-size: inherit;
    font-family: inherit;
    line-height: inherit;
    height: 48px;
    padding: 0 24px;
    width: 100%;
    padding-right: 58px
}

.header__search-input:active, .header__search-input:focus {
    border-color: #f28f14;
    background-color: #fff;
    outline: none
}

.header__search-input:placeholder-shown {
    font-style: italic
}

.header__search-input:active ~ .SearchForm__button > svg > path, .header__search-input:focus ~ .header__search-button > svg > path {
    fill: #f28f14
}

.header__search-button {
    position: absolute;
    right: 0;
    width: 38px;
    height: 48px;
    padding: 0;
    cursor: pointer;
    background: transparent;
    border: none;
    -webkit-transition: -webkit-transform .2s ease-out;
    transition: transform .2s ease-out, -webkit-transform .2s ease-out
}

.header__search-button:active, .header__search-button:focus {
    -webkit-transform: scale(1);
    transform: scale(1);
    outline: none
}

.header__search-button:hover {
    -webkit-transform: scale(1.2);
    transform: scale(1.2)
}

.header__search-button > svg > path {
    -webkit-transition: fill .25s ease-in-out;
    transition: fill .25s ease-in-out
}

.header__search-warning {
    position: absolute;
    bottom: -36px;
    left: 50%;
    z-index: 1000;
    padding: 8px 12px;
    background-color: #f28f14;
    border-radius: 8px;
    opacity: 0;
    font-size: 1rem;
    font-weight: 500;
    line-height: 1;
    white-space: nowrap;
    color: #fff;
    cursor: default;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    -webkit-transition: opacity .25s ease-in-out;
    transition: opacity .25s ease-in-out
}

.header__search-warning--visible {
    opacity: 1;
}

.header__buttons>*+* {
    margin-left: 16px;
}

.header-menu__wrapper {
    margin-top: 40px;
    padding-bottom: 20px;
}

.header-menu__list {
    display: -webkit-box;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    align-items: center;
    margin: 0;
}

.header-menu__item {
    margin: 0 40px 0 0;
}

.header-menu__item a {
    font-weight: 700;
    position: relative;
}

.header-menu__item a:after {
    content: "";
    position: absolute;
    left: 0;
    bottom: -1px;
    width: 100%;
    height: 1px;
    -webkit-transform: scaleX(0);
    transform: scaleX(0);
    -webkit-transform-origin: left;
    transform-origin: left;
    background-color: #f28f14;
    opacity: 1;
    -webkit-transition: -webkit-transform .25s ease-in-out;
    transition: transform .25s ease-in-out,-webkit-transform .25s ease-in-out;
}

.header-menu__item:hover a {
    color: #554937;
}

.header-menu__item:hover a:after {
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
}

/* Header sticky */
.header-sticky {
    min-height: 96px;
    position: fixed;
    width: 100%;
    background-color: #fff;
    box-shadow: 0 4px 20px rgb(85 73 55 / 10%);
    z-index: 1000;
    transform: translateY(-100px);
    transition: all .3s;
}

.header-sticky.show {
    transform: translateY(0);
}

.header-sticky__menu {
    display: none;
}

.header-sticky__grid {
    display: grid;
    grid-template-columns: auto auto 1fr auto;
    grid-template-areas: "logo menu search buttons";
    grid-gap: 0 36px;
}

.header-sticky__logo {
    margin-top: 24px;
}

.header-sticky__logo img {
    width: auto;
    height: 46px;
}

.header-sticky__catalog {
    margin-top: 24px;
}

.header-sticky__search {
    margin-top: 24px;
    position: relative;
}

.header-sticky__buttons {
    margin-top: 24px;
}

.header-sticky__buttons > div {
    width: 48px;
    height: 48px;
    padding: 0;
    margin: 0 10px;
}

.header-menu__container.container {
    display: flex;
}

.header-menu__container.container .btn-catalog {
    margin-right: 25px;
}

.header-menu__list-more {
    cursor: pointer;
    position: relative;
}

.header-menu__list-more span {
    display: flex;
}

.header-menu__list-more img {
    width: 12px;
    margin-left: 7px;
}