@keyframes fade-in {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}


@-webkit-keyframes loader-rotate {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg)
    }

    to {
        -webkit-transform: rotate(1turn);
        transform: rotate(1turn)
    }
}

@keyframes loader-rotate {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg)
    }

    to {
        -webkit-transform: rotate(1turn);
        transform: rotate(1turn)
    }
}

@-webkit-keyframes loader-animation {
    0%,25% {
        stroke-dashoffset: 150;
        -webkit-transform: rotate(0);
        transform: rotate(0)
    }

    50%,75% {
        stroke-dashoffset: 75;
        -webkit-transform: rotate(45deg);
        transform: rotate(45deg)
    }

    to {
        stroke-dashoffset: 150;
        -webkit-transform: rotate(1turn);
        transform: rotate(1turn)
    }
}

@keyframes loader-animation {
    0%,25% {
        stroke-dashoffset: 150;
        -webkit-transform: rotate(0);
        transform: rotate(0)
    }

    50%,75% {
        stroke-dashoffset: 75;
        -webkit-transform: rotate(45deg);
        transform: rotate(45deg)
    }

    to {
        stroke-dashoffset: 150;
        -webkit-transform: rotate(1turn);
        transform: rotate(1turn)
    }
}