.modal-overlay {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1010;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    padding: 15px;
    background: rgba(0, 0, 0, .6);
    -webkit-transition: opacity .15s linear;
    transition: opacity .15s linear;
    opacity: 0;
    visibility: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal-overlay.modal-overlay--active {
    visibility: visible;
    opacity: 1;
}

.modal {
    display: none;
    z-index: 1010;
    box-sizing: border-box;
    margin: 0 auto;
    width: 600px;
    max-width: calc(100% - .01px) !important;
    background: #fff;
    opacity: 0;
    -webkit-transform: translateY(-100px);
    transform: translateY(-100px);
    -webkit-transition: .3s linear;
    transition: .3s linear;
    -webkit-transition-property: opacity, -webkit-transform;
    transition-property: opacity, -webkit-transform;
    transition-property: opacity, transform;
    transition-property: opacity, transform, -webkit-transform;
    padding: 48px;
    border-radius: 4px;
    box-shadow: 0 4px 20px rgba(85, 73, 55, .1);
}

.modal.open {
    display: block;
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0)
}

.modal-close {
    padding: 0;
    top: 16px;
    right: 16px;
    position: absolute;
    z-index: 1021;
}

.modal-close .btn {
    width: 15px;
    padding: 15px;
    height: 15px;
}

.modal-close .btn img {
    width: 12px;
}

/* Auth modal*/

.auth-modal {
    width: 304px;
    padding: 48px;
}

.auth-modal__title {
    margin-bottom: 24px;
    text-align: center;
    font-size: 1.375rem;
    font-weight: 500;
    line-height: 1.3;
}

.auth-modal__subtitle {
    text-align: center;
    font-size: 1rem;
    line-height: 1.3;
}

.auth-modal__form {
    padding: 24px 0;
}

.auth-modal__button .btn {
    width: 100%;
}

.auth-modal__disclamer {
    margin-top: 16px;
    opacity: .5;
    font-size: .75rem;
    line-height: 16px;
}

.auth-modal__disclamer a {
    text-decoration: underline;
    font-weight: 500;
}


/* Feedback modal*/

.feedback-modal {
    width: 592px;
    padding: 48px;
}

.feedback-modal__title {
    margin-bottom: 24px;
    text-align: center;
    font-size: 1.375rem;
    font-weight: 500;
    line-height: 1.3;
}

.feedback-modal__form {
    padding: 24px 0;
}

.feedback-modal__button .btn {
    width: 100%;
}

.feedback-modal__disclamer {
    margin-top: 16px;
    opacity: .5;
    font-size: .75rem;
    line-height: 16px;
}

.feedback-modal__disclamer a {
    text-decoration: underline;
    font-weight: 500;
}


/* Checkout */
.modal.checkout-modal.open {
    padding: 0;
    width: 1264px;
    border-radius: 20px;
    position: absolute;
    top: 50px;
}

.checkout-modal__title {
    display: -webkit-box;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    justify-content: space-between;
    -webkit-box-align: center;
    -webkit-align-items: center;
    align-items: center;
    padding: 32px 32px 32px 64px;
    box-shadow: 0 4px 20px rgb(85 73 55 / 10%);
}

.checkout-modal__title {
    font-size: 2rem;
    font-weight: 500;
    line-height: 1.09;
    color: #554937;
}

.checkout-modal__inner {
    display: grid;
    grid-template-columns: repeat(2,1fr);
    grid-template-areas:
            "personal info"
            "shipping info"
            "bonuses ."
            "payment footer";
    grid-column-gap: 144px;
    padding: 40px 64px 64px;
}

.checkout-modal__block-title {
    font-size: 1.375rem;
    font-weight: 500;
    line-height: 1.3;
}

.checkout-modal__block-body {
    margin-top: 24px;
}

.checkout-modal__block.checkout-modal__block--info {
    grid-area: personal;
    padding-bottom: 48px;
    border-bottom: 1px solid rgba(85,73,55,.2);
}

.checkout-modal__block.checkout-modal__block--delivery {
    grid-area: shipping;
    margin-top: 40px;
    padding-bottom: 40px;
    border-bottom: 1px solid rgba(85,73,55,.2);
}


.checkout-modal__block.checkout-modal__block--payment {
    grid-area: payment;
    margin-top: 40px;
    border-bottom: none;
}

.checkout-modal__block.checkout-modal__block--total {
    position: relative;
    grid-area: info;
    margin-top: 52px;

}

.checkout-modal__block.checkout-modal__block--order {
    grid-area: footer;
    -webkit-align-self: end;
    align-self: end;
    display: -webkit-box;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    justify-content: space-between;
    -webkit-box-align: center;
    -webkit-align-items: center;
    align-items: center;
    padding-bottom: 16px;
}

.checkout-modal__block .checkout-modal__block--sticky {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
}

.checkout-modal__block-summary {
    padding: 32px;
    border-radius: 4px;
    box-shadow: 0 4px 20px rgb(85 73 55 / 10%);
}

.checkout-modal__total-item {
    display: -webkit-box;
    display: -webkit-flex;
    display: flex;
    -webkit-box-align: baseline;
    -webkit-align-items: baseline;
    align-items: baseline;
    margin-top: 16px;
    font-size: 1rem;
    font-weight: 500;
    line-height: 1.2;
    color: #554937;
}

.checkout-modal__total-item:after {
    content: "";
    -webkit-box-ordinal-group: 3;
    -webkit-order: 2;
    order: 2;
    -webkit-box-flex: 1;
    -webkit-flex-grow: 1;
    flex-grow: 1;
    display: inline-block;
    margin: 0 8px;
    border-bottom: 1px dashed rgba(85, 73, 55, .2);
}

.checkout-modal__total-item--label {
    -webkit-box-ordinal-group: 2;
    -webkit-order: 1;
    order: 1;
    -webkit-flex-shrink: 0;
    flex-shrink: 0;
}

.checkout-modal__total-item--value {
    -webkit-box-ordinal-group: 4;
    -webkit-order: 3;
    order: 3;
    -webkit-flex-shrink: 0;
    flex-shrink: 0;
}

.checkout-modal__total-item.checkout-modal__total-item--sum {
    margin-top: 24px;
    font-size: 1.375rem;
    font-weight: 700;
    line-height: 1.2;
}



.checkout-modal__block-buttons {
    display: grid;
    grid-template-columns: repeat(2,1fr);
    grid-gap: 16px;
    font-size: 1rem;
}

.checkout-modal__block-button {
    display: block;
    border: 2px solid rgba(85,73,55,.3);
    border-radius: 4px;
    padding: 16px 16px 16px 24px;
    cursor: pointer;
    -webkit-transition-property: border-color,background-color;
    transition-property: border-color,background-color;
    -webkit-transition: .25s ease-in-out;
    transition: .25s ease-in-out;
    height: 100%;
}

.checkout-modal__block-button.checked {
    border-color: #f28f14;
}

.checkout-modal__block-button-title {
    display: block;
    margin-bottom: 8px;
    font-weight: 500;
    line-height: 1.3;
    color: #554937;
    -webkit-transition: color .25s ease-in-out;
    transition: color .25s ease-in-out;
}

.checkout-modal__block-button-descr {
    display: block;
    line-height: 1.2;
    color: rgba(85,73,55,.5);
}


.checkout-modal__order-disclamer {
    opacity: .5;
    font-size: .75rem;
    line-height: 16px;
    margin-top: 0;
    margin-right: 24px;
}

.checkout-modal__order-disclamer>a, .checkout-modal__order-disclamer>a:hover {
    text-decoration: underline;
}

.checkout-modal__form textarea {
    height: 90px;
    padding: 15px;
}

.checkout-modal__total-item.checkout-modal__total-item--bonuses {
    color: #f28f14;
}

.checkout-modal__block-subtitle {
    margin-top: 5px;
    margin-bottom: -10px;
}

.checkout-modal__shipping-address {
    margin-top: 20px;
}

.checkout-modal__shipping-address--title {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 10px;
}

.checkout-modal__shipping-address--radio {
    margin-top: 20px;
}

.checkout-modal__shipping-address--point {
    margin-top: 20px;
}

.checkout-modal__shipping-address--courier {
    margin-top: 20px;
    display: grid;
    grid-template-columns: 1fr 0.6fr 0.4fr;
    grid-gap: 10px;
}

.checkout-modal__shipping-address--radio-item {
    margin-bottom: 10px;
}