.product-card {
    display: -webkit-box;
    display: -webkit-flex;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    flex-direction: column;
    height: 100%;
    border-radius: 4px;
    background-color: #fff;
    box-shadow: 0 4px 8px rgba(85, 73, 55, .1);
    -webkit-transition: box-shadow .25s ease-in-out;
    transition: box-shadow .25s ease-in-out;
}

.product-card:hover {
    box-shadow: 0 4px 20px rgba(85, 73, 55, .2);
}

.product-card__title {
    transition: color .25s ease-in-out;
    font-size: 1rem;
    font-weight: 500;
    height: 70px;
}

.product-card__body {
    max-height: 100px;
    overflow: hidden;
    padding: 16px 24px;
}

.product-card__footer {
    margin-top: auto;
    padding: 0 24px 24px;
}

.product-card__header {
    display: flex;
    justify-content: center;
    height: 200px;
    border-bottom: 1px solid rgba(85, 73, 55, .2);
}

.product-card__header img {
    object-fit: contain;
}

.product-card__button .btn {
    margin-top: 16px;
    width: 100%;
}

.product-card__price {
    margin-right: 16px;
    font-weight: 700;
    font-size: 1.125rem;
    color: #554937;
    line-height: 1.6;
    display: flex;
}

.product-card__price .value {
    margin-right: 10px;
}

/*Main card*/

.product-main {
    padding: 48px;
    background-color: #fff;
    box-shadow: 0 2px 4px rgba(85, 73, 55, .1);
    display: grid;
    grid-template-columns: 1fr 1.1fr;
    grid-column-gap: 64px;
    margin-bottom: 40px;
}

.product-info__code {
    font-size: .875rem;
    font-family: sans-serif;
    font-stretch: condensed;
    opacity: .5;
}

.product-info__title {
    font-size: 2rem;
    font-weight: 400;
    margin-bottom: 24px;
}

.product-info__available {
    display: block;
    margin-bottom: 48px;
    margin-top: 12px;
}

.product-info__available--shops {
    color: #f28f14;
    cursor: pointer;
    text-decoration: underline;
    -webkit-transition: color .25s ease-in-out;
    transition: color .25s ease-in-out;
    font-weight: 500;
}

.product-info__purchase {
    display: -webkit-box;
    display: -webkit-flex;
    display: flex;
    width: 320px;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    justify-content: space-between;
    -webkit-box-align: center;
    -webkit-align-items: center;
    align-items: center;
}

.product-info__price {
    font-size: 1.5rem;
    font-weight: 700;
    display: flex;
}

.product-info__button {
    --button-height: 48px;
    min-width: 187px;
}

.product-info__price .currency {
    margin-left: 5px;
}

.product-info__button .btn {
    width: 100%;
}

/*Product Swithc*/

.product-switch {
    background-color: #fff;
    box-shadow: 0 2px 4px rgba(85, 73, 55, .1);
    display: block;
    border-radius: 4px;
    overflow: hidden;
    margin-bottom: 77px;
}

.product-switch__tabs {
    padding-left: 48px;
    padding-right: 48px;
    display: -webkit-box;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-left: -20px;
    list-style: none;
}

.product-switch__tab {
    -webkit-box-flex: 0;
    -webkit-flex: none;
    flex: none;
    position: relative;
    display: grid;
    grid-template-columns: -webkit-max-content 1fr;
    grid-template-columns: max-content 1fr;
    grid-column-gap: 16px;
    -webkit-box-align: center;
    -webkit-align-items: center;
    align-items: center;
    padding: 24px 20px;
    cursor: pointer;
    margin: 0 10px;
}

.product-switch__tab > * {
    color: #999;
    font-size: 16px;
}

.product-switch__tab.active > *,
.product-switch__tab:hover > * {
    color: #f28f14
}

.product-switch__tab.active:after, .product-switch__tab:hover:after {
    -webkit-transform: scaleX(1);
    transform: scaleX(1)
}

.product-switch__tab:after {
    position: absolute;
    content: "";
    width: 100%;
    height: 4px;
    background-color: #f28f14;
    bottom: 0;
    -webkit-transform: scaleX(0);
    transform: scaleX(0);
    -webkit-transition: -webkit-transform .25s ease-in-out;
    transition: -webkit-transform .25s ease-in-out;
    transition: transform .25s ease-in-out;
    transition: transform .25s ease-in-out, -webkit-transform .25s ease-in-out
}

.product-switch__body {
    padding: 48px
}

.product-switch__content-title {
    font-size: 2rem;
    line-height: 1.5;
    margin-bottom: 24px;
    font-weight: 500;
    color: #554937;
}

.product-switch__content {
    display: none;
}

.product-switch__content.active {
    display: block;
}

.product-switch__availability-offer {
    font-size: 1.125rem;
    margin-bottom: 16px;
    display: flex;
    justify-content: space-between;
}

.product-switch__availability-offer > *:not(:nth-child(1)) {
    margin-left: 10px;
}

.product-balance {
    margin-right: 10px;
}
.product-balance {
    /* background: red; */
    width: 20px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.product-balance-stick {
    background: #999999;
    width: 100%;
    height: 4px;
    border-radius: 5px;
}

.product-balance-stick + .product-balance-stick {
    margin-top: 3px;
}

.product-balance--many .product-balance-stick {
    background: #86be28;
}

.product-balance--few .product-balance-stick:nth-child(2),
.product-balance--few .product-balance-stick:nth-child(3){
    background: #f28f14;
}

.product-balance--last .product-balance-stick:nth-child(3) {
    background: #f21414;
}

.product-switch__availability-offer--address {
    display: flex;
    order: 1;
    flex-shrink: 0;
}

.product-switch__availability-offer--price {
    order: 3;
    flex-shrink: 0;
}

.product-switch__availability-offer:after {
    content: "";
    order: 2;
    flex-grow: 1;
    display: inline-block;
    margin: 0 8px;
    border-bottom: 1px dashed rgba(85, 73, 55, .2);
}

.product-gallery {
    max-height: 320px;
    display: flex;
    justify-content: center;
}

.product-gallery img {
    max-height: 100%;
    object-fit: contain;
}