
.loader {
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
    z-index: -10;
    height: 100%;
    width: 100%;
}

.loader.loader-small {
    width: 32px;
    height: 32px;
}

.loader.loader-small .lds-ring {
    width: 32px;
    height: 32px;
}

.loader.loader-small .lds-ring div {
    width: 24px;
    height: 24px;
}

.loader.loader-fullsize {
    width: 100vw;
    height: 50vh;
}

.lds-ring {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
}
.lds-ring div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 64px;
    height: 64px;
    margin: 3px;
    border: 3px solid #88B04B;
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #88B04B transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
    animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
    animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
    animation-delay: -0.15s;
}
@keyframes lds-ring {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}